import Flex from "components/common/Flex";
import { ChatContext } from "context/Context";
import { useContext, useEffect, useState } from "react";
import { Card, Modal, Tab } from "react-bootstrap";
import ChatProvider from "./ChatProvider";
import ChatContent from "./content/ChatContent";
import ChatSidebar from "./sidebar/ChatSidebar";
import { useDispatch, useSelector } from "react-redux";
import { getActiveWorkSpace, setSearchHint } from "redux/slices/workspaceslice";
import ChatSetup from "./setup/ChatSetup";
import MessagingAccountsConnector from "./setup/MessagingAccountsConnector";
import { emitReadMessages } from "./chatSocket";

export const markMessageAsRead = (
  chat,
  setHideSidebar,
  setIsOpenThreadInfo,
  setActiveRoomId,
  setSenderId,
  currentChats,
  conversationsDispatch,
  currentChatsDispatch,
  setCurrentConversation,
  setScrollToBottom
) => {
  setHideSidebar(false);
  setIsOpenThreadInfo(false);

  const currentChat = currentChats?.find(
    (thread) => thread?.room_id === chat?.room_id
  );
  const otherUserId = currentChat?.participants?.find(
    (id) => id !== chat?.user_id
  );

  setActiveRoomId(currentChat?.room_id);
  setSenderId(otherUserId);

  const updatedMessages = currentChat?.messages?.map((message) => {
    if (message?.sender !== currentChat?.user_id) {
      if (!message?.read) {
        return { ...message, read: true, status: "seen" };
      }
    }
    return message;
  });

  //? Create a new object with the updated messages property
  const updatedChat = {
    ...currentChat,
    messages: updatedMessages,
  };

  const lastMessage = updatedChat?.messages[updatedChat?.messages.length - 1];
  conversationsDispatch({
    type: "ADD_MESSAGE",
    payload: {
      ...updatedChat,
      last_message_read: lastMessage.read,
    },
    room_id: updatedChat?.room_id,
  });

  currentChatsDispatch({
    type: "ADD_MESSAGE",
    payload: {
      ...updatedChat,
      last_message_read: lastMessage.read,
    },
    room_id: updatedChat?.room_id,
  });

  const lastSender =
    updatedChat?.messages[updatedChat.messages.length - 1]?.sender;
  //? Send to socket
  if (updatedChat && otherUserId && updatedChat.user_id !== lastSender) {
    emitReadMessages(updatedChat?.room_id, otherUserId);
  }

  setCurrentConversation(updatedChat);
  setScrollToBottom(true);
};

const ChatTab = () => {
  const dispatch = useDispatch();
  const workSpace = useSelector(getActiveWorkSpace);
  const [chatSettings, setChatSettings] = useState(
    workSpace.chat_settings ?? {}
  );

  const { hideSidebar, setHideSidebar } = useContext(ChatContext);

  const [openAccountsConnector, setOpenAccountsConnector] = useState(false);

  const reloadPage = () => {
    setOpenAccountsConnector(false);
    let latestChatSettings = workSpace?.chat_settings;
    setChatSettings({ ...latestChatSettings });
  };

  const connectAccounts = () => {
    setOpenAccountsConnector(true);
  };

  useEffect(() => {
    dispatch(setSearchHint("Search contacts..."));
  }, []);

  return (
    <Tab.Container id="social-inbox-tab" defaultActiveKey="0">
      <Card className="card-chat overflow-hidden">
        {!chatSettings.ready_for_messaging && (
          <ChatSetup connectAccounts={connectAccounts} />
        )}
        {chatSettings.ready_for_messaging && (
          <Card.Body as={Flex} className="p-0 h-100">
            <ChatSidebar
              connectAccounts={connectAccounts}
              hideSidebar={hideSidebar}
            />
            <ChatContent setHideSidebar={setHideSidebar} />
          </Card.Body>
        )}
      </Card>

      <Modal
        show={openAccountsConnector}
        onHide={() => {
          reloadPage();
        }}
        onExit={() => {
          reloadPage();
        }}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Connect Accounts to Inbox</Modal.Title>
        </Modal.Header>
        <MessagingAccountsConnector />
      </Modal>
    </Tab.Container>
  );
};

const Chat = () => {
  return (
    <ChatProvider>
      <ChatTab />
    </ChatProvider>
  );
};

export default Chat;