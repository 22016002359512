/* eslint-disable no-unused-vars */
import usePubSub from 'pubsub';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllUsers, getReturningUsers, getUsersSignup, setActivePages, setAllUsers, setBotRequestsCount, setOnlineUsersCount, setReturningUsers, setTotalUsers, setTotalWorkSpaces, setUsersSignup } from 'redux/slices/admin_slice';
import {
    setPostlyCloudTotalStorageLimit,
    setPostlyCloudUsedBytes
} from 'redux/slices/postslice';
import { getLoggedInUser, setLoggedInUser, setUserBillingUpdate } from 'redux/slices/user_slice';
import {
    getActiveWorkSpace,
    setActiveWorkspace
} from 'redux/slices/workspaceslice';
import '../App.css';
import APIService from 'http/api_service';
import { RoutePaths } from 'constants';


const SocketEventListeners = () => {
    const {
        addPubSubEventListener,
        putUserOnlineViaPubSub,
        removePubSubEventListener,
        socket
    } = usePubSub();
    const loggedInUser = useSelector(getLoggedInUser);
    const workSpace = useSelector(getActiveWorkSpace);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const returningUsers = useSelector(getReturningUsers)
    const usersSignup = useSelector(getUsersSignup)
    const allUsers = useSelector(getAllUsers)
    const query = new URLSearchParams(window.location.search);
    const meta_vdx = query.get("meta_vdx");

    const updateUserDataAndWorkspace = async (verification, type = 'subscription_successful', data = {}) => {
        if (type) {
            dispatch(setUserBillingUpdate({
                newUpdate: true,
                type,
                data
            }))
            return;
        }
        APIService.fetchLatestMe((latestMeResponse, error) => {
            if (error) {
                if (
                    error
                        .toLowerCase()
                        .includes("Invalid credentials provided".toLowerCase())
                ) {
                    window.localStorage.clear();
                    setTimeout(() => {
                        navigate(RoutePaths.AUTHENTICATION);
                    }, 100);
                }
                return;
            }
            if (verification) {
                let oldPlan = loggedInUser?.active_plan;
                let newPlan = latestMeResponse.data?.active_plan;
                if (oldPlan?.Key !== newPlan?.Key && verification === newPlan?.Key) {
                    APIService.fetchWorkSpace(workSpace?._id, (res, err) => {
                        if (err) {
                            dispatch(setUserBillingUpdate({
                                newUpdate: true,
                                type,
                                data
                            }))
                            return;
                        }
                        const latestWorkspaceData = res.data;
                        dispatch(setActiveWorkspace({ ...latestWorkspaceData }));
                        dispatch(setUserBillingUpdate({
                            newUpdate: true,
                            type,
                            data
                        }))
                    });
                }
                return;
            }
            let latestUserData = latestMeResponse.data;
            dispatch(setLoggedInUser({ ...latestUserData }));
            APIService.fetchWorkSpace(workSpace?._id, (res, err) => {
                if (err) {
                    dispatch(setUserBillingUpdate({
                        newUpdate: true,
                        type,
                        data
                    }))
                    return;
                }
                const latestWorkspaceData = res.data;
                dispatch(setActiveWorkspace({ ...latestWorkspaceData }));
                dispatch(setUserBillingUpdate({
                    newUpdate: true,
                    type,
                    data
                }))
            });
        });
    };

    useEffect(() => {
        if (!meta_vdx) return;
        updateUserDataAndWorkspace(meta_vdx)
    }, [meta_vdx])

    // action handlers
    const handleNewSubscription = (type = "subscription_successful", data = {}) => {
        updateUserDataAndWorkspace(null, type, data);
    }

    const handleBackgroundResponse = ({ error, data, key, ...others }) => {
        if (error) {
            key === 'postly_cloud_usage' && dispatch(setPostlyCloudUsedBytes('unavailable'));
            return;
        }
        if (key === 'postly_cloud_usage') {
            dispatch(setPostlyCloudTotalStorageLimit(data?.total));
            dispatch(setPostlyCloudUsedBytes(data?.used));
        }
        if (key === 'admin_metrics_returning_users') {
            dispatch(setReturningUsers({ ...returningUsers, daily: data?.daily, every_3_days: data?.every_3_days, every_7_days: data?.every_7_days, every_30_days: data?.every_30_days, every_60_days: data?.every_60_days, every_90_days: data?.every_90_days, every_180_days: data?.every_180_days, every_365_days: data?.every_365_days }))
        }
        if (key === 'admin_metrics_users_signup') {
            dispatch(setUsersSignup({
                ...usersSignup,
                daily: data?.daily,
                weekly: data?.weekly,
            }))
        }
        if (key === 'admin_metrics_total_users') {
            dispatch(setTotalUsers(data))
        }
        if (key === 'admin_metrics_total_workspaces') {
            dispatch(setTotalWorkSpaces(data))
        }
        if (key === 'admin_metrics_all_users_details') {
            if (data?.skip === 0) {
                dispatch(setAllUsers(data))
            } else {
                dispatch(setAllUsers([...allUsers, ...data]))
            }
        }
        if (key === 'admin_metrics_online_users_count') {
            dispatch(setOnlineUsersCount(data))
        }
        if (key === 'admin_metrics_active_pages') {
            dispatch(setActivePages(data))
        }
        if (key === 'admin_metrics_bot_requests_count') {
            dispatch(setBotRequestsCount(data))
        }
        if (key === 'billing_updates:trialing_cancelled') {
            dispatch(setUserBillingUpdate({
                newUpdate: true,
                type: 'trialing_cancelled',
                data: data
            }))
        }
        if (key === 'billing_updates:trialing_renewed') {
            dispatch(setUserBillingUpdate({
                newUpdate: true,
                type: 'trialing_renewed',
                data: data
            }))
        }
        if (key === 'billing_updates:subscription_successful') {
            handleNewSubscription();
        }
        if (key === 'billing_updates:subscription_cancelled') {
            handleNewSubscription('subscription_cancelled');
        }
        if (key === 'billing_updates:subscription_updated') {
            handleNewSubscription('subscription_updated', data);
        }
    }

    useEffect(() => {
        if (!workSpace?._id || !socket?.connected) return
        let apiKey = loggedInUser['api_key']
        socket.on(`${apiKey}_background_process_response`, handleBackgroundResponse);
        return () => {
            socket.off(`${apiKey}_background_process_response`, (res) => { });
        }
    }, [workSpace?._id, socket?.connected]);

    return <></>
}

export default SocketEventListeners