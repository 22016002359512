import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'
import { getPopupAppreciationMessage, getPopupPrimaryMessage, getPopupSecondaryMessage, getPopupUpgradeAlert, getPopupUpgradeAlertForLTD, getPopupUpgradeAlertGoBackOnCancel, setPopupAppreciationMessage, setPopupPrimaryMessage, setPopupSecondaryMessage, setPopupUpgradeAlert, setPopupUpgradeAlertForLTD } from 'redux/slices/authSlice';
import { RoutePaths } from 'constants';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { getActiveMetric, getCollectMetric, setActiveMetric } from 'redux/slices/analytics_slice';
import APIService from 'http/api_service';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { getLoggedInUser, setActionForBillingUpgradeModal, setShowFreeUserBillingUpgradeModal } from 'redux/slices/user_slice';
import StringUtils from 'utils/string';
import Flex from '../Flex';

const FeatureBlockModal = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const workSpace = useSelector(getActiveWorkSpace);
    const loggedInUser = useSelector(getLoggedInUser);
    const popupUpgradeAlert = useSelector(getPopupUpgradeAlert)
    const popupUpgradeAlertForLTD = useSelector(getPopupUpgradeAlertForLTD);
    const popupUpgradeAlertGoBackOnCancel = useSelector(getPopupUpgradeAlertGoBackOnCancel);
    const activeMetric = useSelector(getActiveMetric)
    const collectMetric = useSelector(getCollectMetric)

    const pushOutMetricToServer = (metric) => {
        if (!metric) return;
        APIService.newMetric(metric, (response, error) => {
            if (error) {
                console.log(error)
                return;
            }
        })
    }

    const collectMetrics = (userResponse = false, destroyActiveMetric = false) => {
        if (!userResponse) {
            if (!collectMetric) return;
            const initMetric = {
                metricId: uuid(),
                data: {
                    workspace_id: workSpace?._id,
                    userId: loggedInUser?._id,
                    userEmail: loggedInUser?.email,
                    timestamp: collectMetric?.timestamp || new Date().toISOString(),
                    ...collectMetric
                }
            }
            pushOutMetricToServer(initMetric);
            dispatch(setActiveMetric(initMetric))
            return
        }
        if (!activeMetric) return;
        const updatedMetric = {
            ...activeMetric,
            data: {
                ...activeMetric.data,
                responses: activeMetric?.data?.responses?.length ? [...activeMetric?.data?.responses, userResponse] : [userResponse]
            }
        }
        pushOutMetricToServer(updatedMetric);
        if (destroyActiveMetric) {
            dispatch(setActiveMetric(null))
        } else {
            dispatch(setActiveMetric(updatedMetric))
        }
    }


    const closeModal = () => {
        let response = {
            responseId: uuid(),
            timestamp: new Date().toISOString(),
            response: "Closed upgrade notice",
            buttonClicked: 'CLOSE'
        }
        collectMetrics(response, true)
        if (popupUpgradeAlertGoBackOnCancel) {
            navigate(-1)
        }
        dispatch(setPopupUpgradeAlert(false))
        dispatch(setPopupUpgradeAlertForLTD(false));
        if (activeMetric?.data?.action) {
            let action = activeMetric?.data?.action;
            action = StringUtils.convertSnakeCaseToUpperCase(action);
            dispatch(setActionForBillingUpgradeModal(action))
        }
        dispatch(setShowFreeUserBillingUpgradeModal(true))
    }

    const displayMessages = () => {
        if (popupUpgradeAlertForLTD) {
            return <Modal.Body>
                <p>
                    This feature isn't available on your current plan. You can upgrade with a one-time fee or switch to one of our subscription plans to access it.
                </p>
                <p>
                    Thank you for your understanding and support!
                </p>
            </Modal.Body>
        }
        return <Modal.Body>
            <p>
                To access this feature, please upgrade your account. Upgrading unlocks additional benefits and enhanced functionality.
            </p>

            <p>
                If you have any questions, our support team is here to help.
            </p>
            <p>
                Thank you!
            </p>
        </Modal.Body>
    }


    useEffect(() => {
        collectMetrics()
    }, [collectMetric])

    return (
        <div>
            <Modal
                size="md"
                centered
                show={popupUpgradeAlert}
                onHide={() => closeModal()}
            >
                <Modal.Header>
                    <span
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <Modal.Title>
                                Hi there 👋
                            </Modal.Title>
                        </div>
                    </span>
                </Modal.Header>
                {displayMessages()}
                <Modal.Footer style={{ flexDirection: "column" }}>
                    <Flex justifyContent={"flex-start"} alignItems={"center"} gap={10}>
                        <Button
                            variant="danger"
                            onClick={closeModal}>No thanks</Button>
                        <Button onClick={() => {
                            let response = {
                                responseId: uuid(),
                                timestamp: new Date().toISOString(),
                                response: `Clicked on upgrade button for ${popupUpgradeAlertForLTD ? 'LTD' : 'Subscription'} plan`,
                                buttonClicked: 'UPGRADE'
                            }
                            collectMetrics(response, false)
                            dispatch(setPopupUpgradeAlert(false))
                            dispatch(setPopupUpgradeAlertForLTD(false))
                            navigate(RoutePaths.BILLING_STRIPE)
                        }}>Yes, upgrade</Button>
                    </Flex>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default FeatureBlockModal