import APIService from "http/api_service";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { toast } from "react-toastify";
import {
  getBitlyToken,
  getBitlyTokenArray,
  getLinksFolders,
  setBitlyToken,
  setBitlyTokenArray,
  setLinksFolders,
} from "redux/slices/links_shortener_slice";
import { getActiveWorkSpace, setSearchHint } from "redux/slices/workspaceslice";
import { useDispatch, useSelector } from "react-redux";
import Flex from "components/common/Flex";
import CopyLinkButton from "components/common/copylinkbutton";
import { copyToClipboard } from "utils/browser";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdSettings } from "react-icons/io";
import useProtectedRoute from "hooks/useProtectedRoute";
import { RoutePaths } from "constants";

const Integrations = () => {
  const dispatch = useDispatch();
  const [shortLink, setShortLink] = useState();
  const [tab, setTab] = useState(1);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [displayNewFolderInput, setDisplayNewFolderInput] = useState(false);
  const [folderActionhandler, setFolderActionhandler] = useState("Create");
  const [allowLinksToExpire, setAllowLinksToExpire] = useState(false);
  const [saveLinkData, setSaveLinkData] = useState({
    link_name: "",
    save_folder: "",
    link_url: "",
  });
  const [formData, setFormData] = useState({
    full_link: "",
  });
  const [generatingShortLink, setGeneratingShortLink] = useState();
  const workSpace = useSelector(getActiveWorkSpace);
  const linksFolders = useSelector(getLinksFolders);
  const bitlyToken = useSelector(getBitlyToken);
  const bitlyTokenArray = useSelector(getBitlyTokenArray);
  const [workSpaceOwner, setWorkspaceOwner] = useState();
  const [saveButtonState, setSaveButtonState] = useState("Save");
  const [apiToken, setApiToken] = useState("");
  const [tokenHandler, setTokenHandler] = useState("");
  const [selectedToken, setSelectedToken] = useState("");
  const [onSubmitting, setOnSubmitting] = useState({
    submit: false,
    setTokenStatus: false,
  });
  const { status } = useProtectedRoute(['free'], `${RoutePaths.ALL_BILLING}/#schedulerSection001`)


  const fetchWorkspaceOwner = () => {
    APIService.fetchWorkSpaceOwner(workSpace._id, (response, error) => {
      if (response) {
        let { data } = response;
        setWorkspaceOwner(data);
      }
    });
  };

  useEffect(() => {
    dispatch(setSearchHint("Search..."));
    fetchWorkspaceOwner();
  }, []);

  const userOnFreePlan = () => {
    let activePlan = workSpaceOwner?.active_plan;
    if (
      activePlan?.Name?.toLowerCase() === "free" ||
      activePlan?.Key?.toLowerCase() === "free"
    ) {
      return true;
    }
    return false;
  };

  const getToken = async () => {
    APIService.getBitlyToken((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data } = response;
      //   toast.success(message);
      dispatch(setBitlyToken(data));
    });
  };

  const handleInputChanges = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setGeneratingShortLink(true);
    APIService.bitlyLinkShortener(formData, async (response, error) => {
      setGeneratingShortLink(false);
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data } = response;
      // await getToken();
      setShortLink(data);
      setSaveLinkData({ ...saveLinkData, link_url: data });
      document.getElementById("link-shortener-footer")?.scrollIntoView();
    });
  };

  const saveLinkHandler = async (e) => {
    e.preventDefault();
    if (!saveLinkData.link_name) {
      toast.error("Input link name", { theme: "colored" });
      return;
    }
    setSaveButtonState("Saving...");
    APIService.saveLink(saveLinkData, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        setSaveButtonState("Save");
        return;
      }
      let { message } = response;
      toast.success(message);
      setSaveButtonState("Save");
      setSaveLinkData({
        ...saveLinkData,
        link_name: "",
        save_folder: "",
        link_url: "",
      });
      setShortLink("");
      setShowSaveModal(false);
    });
  };

  //get folders
  const fetchFoldersHandler = async (e) => {
    APIService.fetchFolders((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { message, data } = response;
      //   toast.success(message);
      dispatch(setLinksFolders(data));
    });
  };

  //create folder
  const createFolderHandler = async (e) => {
    e.preventDefault();
    if (!newFolderName) {
      toast.error("Input folder name", { theme: "colored" });
      return;
    }
    setFolderActionhandler("Creating...");
    let requestBody = {
      folder_name: newFolderName,
    };
    APIService.createLinkFolder(requestBody, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        setFolderActionhandler("Create Folder");

        return;
      }
      let { message } = response;
      toast.success(message);
      fetchFoldersHandler();
      setFolderActionhandler("Create");
      setDisplayNewFolderInput(false);
      setNewFolderName("");
    });
  };

  const handleNewFolderClick = () => {
    setDisplayNewFolderInput(true);
  };

  const addNewBitlyToken = async () => {
    if (!tokenHandler) {
      toast.error("Kindly provide your bitly access token.", {
        theme: "colored",
      });
      return;
    }
    setOnSubmitting({ ...onSubmitting, submit: true });
    APIService.addBitlyToken({ token: tokenHandler }, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        setOnSubmitting({ ...onSubmitting, submit: false });
        return;
      }
      let { data, message, useToken } = response;
      console.log("🚀 ~ APIService.addBitlyToken ~ data:", data);
      toast.success(message);
      setTokenHandler("")
      dispatch(setBitlyTokenArray(data));
      {!bitlyToken && dispatch(setBitlyToken(useToken))};
      setOnSubmitting({ ...onSubmitting, submit: false });
    });
  };


  const setNewBitlyToken = async (arg) => {
    setOnSubmitting({ ...onSubmitting, setTokenStatus: true });
    APIService.setBitlyToken({ token: arg }, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        setOnSubmitting({ ...onSubmitting, setTokenStatus: false });
        return;
      }
      let { data, message } = response;
      toast.success(message);
      dispatch(setBitlyToken(data));
    });
  };


  const TabComponent = () => {
    switch (tab) {
      case 1:
        return (
          <>
          <Col>
          <Card.Title>Select token to use</Card.Title>
          {bitlyTokenArray?.length > 0 ? bitlyTokenArray?.map((token, index) => (
                <Flex
                  key={index}
                  className="d-flex justify-content-start align-items-center gap-3 mb-3"
                >
                  <Form.Check
                    style={{
                      cursor: "pointer",
                    }}
                    type="radio"
                    onChange={async () => {
                      setSelectedToken(token);
                     await setNewBitlyToken(token)
                    }}
                    checked={bitlyToken === token ? true : false}
                  />
                  <span>{token}</span>
                </Flex>
              )) : <Card.Text>No token available.</Card.Text>}
          </Col>
          <Col className="mt-5">
          <Card.Title>Add token</Card.Title>
          <Form.Control
              type="text"
              placeholder="Submit Bitly Access Token"
              aria-label="Long Link Text box"
              value={tokenHandler}
              name={"token"}
              onChange={(e) => setTokenHandler(e.target.value)}
              className="container"
              style={{ width: "50%", margin: "0px" }}
            />
            <Button
              onClick={addNewBitlyToken}
              disabled={onSubmitting?.submit}
              className="mt-3"
            >
              {!onSubmitting?.submit ? "Submit Token" : "Submitting..."}
            </Button>
          </Col>
           
          </>
        );
      // return <Shortener tabRef={tabRef} setTab={setTab} />;
      case 2:
        return (
          <>
            {selectedToken && (
              <div className="d-flex justify-content-end mb-3">
                <Button type="button" disabled={onSubmitting?.setTokenStatus} onClick={setNewBitlyToken}>
                  Use token
                </Button>
              </div>
            )}
            <ListGroup>
              {bitlyTokenArray?.map((token, index) => (
                <ListGroup.Item
                  key={index}
                  className="d-flex justify-content-start align-items-center gap-3"
                >
                  <Form.Check
                    style={{
                      cursor: "pointer",
                    }}
                    type="checkbox"
                    onChange={() => setSelectedToken(token)}
                    checked={selectedToken === token ? true : false}
                  />
                  <span>{token}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        );
      default:
        return;
    }
  };
  return (
    <>
      <Card className="mx-2 mt-2">
        <Card.Body className="text-center py-5">
          {/* <Flex id="default-token" className="d-flex justify-content-end mb-4 align-items-center gap-4">
            
          </Flex> */}
          <Row className="justify-content-center">
            <Col xs={7} md={5}>
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToo6OENmEdhlwbY_OgSZlGTxwcn2-it7c5tB15P-WVKJ_C6TIOk2DVkeP6N3h_wtFFZp0&usqp=CAU"
                className="img-fluid"
                alt="bitly_logo"
                width="20%"
              />
            </Col>
          </Row>
          <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
            Shorten your Links
          </h3>
          <Row className="justify-content-center">
            {
              // userOnFreePlan() ?
              //     <div>
              //         <p>Upgrade your account to use this service</p>
              //         <Button as={Link} to={`${RoutePaths.ACCOUNT}?t=billing`}>Upgrade</Button>
              //     </div>
              //     :
              <Col md={7}>
                <Form onSubmit={handleSubmit} className="g-2">
                  <Row>
                    <Col sm>
                      <Form.Label>Paste a long URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter long link here"
                        aria-label="Long Link Text box"
                        value={formData.full_link}
                        name={"full_link"}
                        onChange={handleInputChanges}
                        style={{ width: "100%", margin: "0px" }}
                      />
                    </Col>
                    {/* <Col sm="auto">
                                            <Button
                                                onClick={handleSubmit}
                                                variant="primary"
                                                disabled={!formData.full_link || generatingShortLink}
                                                className="d-block w-100">
                                                {generatingShortLink ? "Please wait..." : "Get Short Link"}
                                            </Button>
                                        </Col> */}
                  </Row>
                  {/* <Row style={{ margin: "10px 0px" }}>
                  <Col sm>
                    <Form.Label>Domain</Form.Label>
                    <div style={{ width: "100%", position: "relative" }}>
                      <Form.Control
                        type="text"
                        placeholder="postly.link"
                        aria-label="Long Link Text box"
                        value={formData.custom_domain}
                        name={"custom_domain"}
                        disabled={userOnFreePlan() ? true : false}
                        onChange={handleInputChanges}
                        // style={{width: "100%"}}
                      />
                      {userOnFreePlan() && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0px",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            paddingRight: "10px",
                          }}
                        >
                          <MdLock
                            style={{ cursor: "pointer" }}
                            title="Upgrade your account to access Custom domain"
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col sm>
                    <Form.Label>Back-half (optional)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="example: favourite-link"
                      aria-label="Long Link Text box"
                      value={formData.back_half}
                      name={"back_half"}
                      onChange={handleInputChanges}
                      // style={{width: "100%"}}
                    />
                  </Col>
                </Row> */}
                  <Row>
                    <Col sm="auto">
                      <Button
                        onClick={handleSubmit}
                        variant="primary"
                        disabled={!formData.full_link || generatingShortLink}
                        className="d-block w-100 mt-2"
                      >
                        {generatingShortLink
                          ? "Please wait..."
                          : "Get Short Link"}
                      </Button>
                    </Col>
                  </Row>
                  {/* {formData.full_link && (
                  <Flex
                    alignItems={"center"}
                    className={"gap-3"}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Form.Label
                      style={{
                        paddingTop: 8,
                      }}
                      htmlFor="allow-short-links-to-expire-check"
                    >
                      Allow this Link to expire?
                    </Form.Label>
                    <Form.Check
                      id="allow-short-links-to-expire-check"
                      type="switch"
                      checked={allowLinksToExpire}
                      onChange={(e) => {
                        setAllowLinksToExpire(e.target.checked);
                        if (!e.target.checked) {
                          setFormData((prevData) => {
                            prevData = { ...prevData };
                            delete prevData.expireAt;
                            delete prevData.fall_back_url_after_expiration;
                            return { ...prevData };
                          });
                        } else {
                          setFormData({
                            ...formData,
                            expireAt: new Date(),
                          });
                        }
                      }}
                    />
                  </Flex>
                )} */}
                  {/* {allowLinksToExpire && (
                  <Flex direction={"column"}>
                    <Divider />
                    <Row className="gap-3">
                      <Col sm={12}>
                        <Form.Group className="text-start">
                          <Form.Label>When to Expire</Form.Label>
                          <ReactDatePicker
                            className="form-control"
                            selected={whenToExpire}
                            minDate={new Date()}
                            onChange={(newDate) => {
                              setWhenToExpire(newDate);
                              setFormData({
                                ...formData,
                                expireAt: newDate,
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group className="text-start">
                          <Form.Label>Fallback Link</Form.Label>
                          <Form.Control
                            type={"text"}
                            placeholder={
                              "Specify a fallback link when your link expires"
                            }
                            value={formData?.fall_back_url_after_expiration}
                            name={"fall_back_url_after_expiration"}
                            onChange={handleInputChanges}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Flex>
                )} */}
                </Form>
              </Col>
            }
          </Row>
        </Card.Body>
        {shortLink && (
          <Card.Footer id="link-shortener-footer" className="p-0">
            <Card.Header>
              <Card.Title>
                <span>Link Shortened</span>
              </Card.Title>
            </Card.Header>
            <Card.Body className="bg-light">
              <Row>
                <Col lg={6}>
                  <Flex alignItems={"center"} className={"pt-2"}>
                    <span
                      style={{
                        maxWidth: 300,
                      }}
                    >
                      {formData?.full_link}
                    </span>
                  </Flex>
                </Col>
                <Col lg={6}>
                  <Flex alignItems={"center"} className={"gap-4"}>
                    <a
                      style={{
                        maxWidth: 300,
                      }}
                      href={shortLink}
                      target={"_blank"}
                      rel={"noreferrer"}
                    >
                      {shortLink}
                    </a>
                    <CopyLinkButton
                      defaultText={"Copy"}
                      variant={"outline-info"}
                      handleCopy={() => {
                        copyToClipboard(shortLink);
                      }}
                    />
                    <Button onClick={() => setShowSaveModal(true)}>Save</Button>
                  </Flex>
                </Col>
              </Row>
            </Card.Body>
          </Card.Footer>
        )}
        <Modal
          size="lg"
          centered
          show={showSaveModal}
          onHide={() => setShowSaveModal(false)}
          onExit={() => setShowSaveModal(false)}
        >
          <div style={{ padding: "0px" }}>
            <div style={{ border: "1px solid #bfc9d7" }}>
              <Modal.Header>
                <span
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Modal.Title>Save In</Modal.Title>
                  </div>
                  <AiOutlineClose
                    cursor={"pointer"}
                    onClick={() => setShowSaveModal(false)}
                  />
                </span>
              </Modal.Header>
              <Modal.Body>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <h5>Name</h5>
                  <Form.Control
                    type="text"
                    placeholder="Enter link name"
                    aria-label="Long Link Text box"
                    value={saveLinkData.link_name}
                    name={"link_name"}
                    onChange={(e) =>
                      setSaveLinkData({
                        ...saveLinkData,
                        link_name: e.target.value,
                      })
                    }
                    // style={{width: "100%"}}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <h5>Folder</h5>
                  <Form.Select
                    style={{ cursor: "pointer" }}
                    aria-label="Default select example"
                    onChange={(e) =>
                      setSaveLinkData({
                        ...saveLinkData,
                        save_folder: e.target.value,
                      })
                    }
                    value={saveLinkData.save_folder}
                    name={"save_folder"}
                  >
                    <option style={{ cursor: "pointer" }} value="">
                      Open this select menu
                    </option>
                    {linksFolders?.map((folder, index) => (
                      <option
                        key={index}
                        style={{ cursor: "pointer" }}
                        value={folder?._id}
                      >
                        {folder?.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "20px",
                    marginTop: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    {displayNewFolderInput && (
                      <Form.Control
                        type="text"
                        placeholder="Folder name"
                        aria-label="Long Link Text box"
                        value={newFolderName}
                        name={"newFolderName"}
                        onChange={(e) => {
                          setNewFolderName(e.target.value);
                          setFolderActionhandler("Create Folder");
                          if (!e.target.value) {
                            setFolderActionhandler("Create");
                            setDisplayNewFolderInput(false);
                          }
                        }}
                        // style={{width: "100%"}}
                      />
                    )}
                    {
                      <Button
                        style={{
                          alignSelf: "flex-end",
                          backgroundColor: "#1e63fe",
                          minHeight: 35,
                        }}
                        onClick={
                          folderActionhandler === "Create"
                            ? handleNewFolderClick
                            : createFolderHandler
                        }
                        size="sm"
                        className={`px-3 px-sm-2`}
                        //   disabled={folderToMoveId ? false : true}
                      >
                        {folderActionhandler}
                      </Button>
                    }
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      style={{
                        alignSelf: "flex-end",
                        backgroundColor: "#fafbfd",
                        minHeight: 35,
                        color: "#7f8894",
                      }}
                      onClick={() => setShowSaveModal(false)}
                      size="sm"
                      className={`px-3 px-sm-5`}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        alignSelf: "flex-end",
                        backgroundColor: "#1e63fe",
                        minHeight: 35,
                      }}
                      onClick={saveLinkHandler}
                      size="sm"
                      className={`px-3 px-sm-5`}
                      disabled={saveLinkData.link_name ? false : true}
                    >
                      {saveButtonState}
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </div>
          </div>
        </Modal>
      </Card>
      <Card className="m-2 pb-3 px-3">
        <Flex
          alignItems={"center"}
          className={"gap-3 p-2"}
          style={{ borderBottom: "1px solid #000" }}
        >
          <IoMdSettings size={20} />
          <h3 className="fw-normal fs-2 fs-md-2">Settings</h3>
        </Flex>
        <Card.Title style={{color: "#2c7be5", borderBottom: "1px solid #2c7be5", width: "fit-content", marginTop: "10px", fontSize: "14px"}}>Manage Tokens</Card.Title>
        <br />
        <span style={{fontSize: "12px", fontWeight: "bold"}}>Get your Bitly token, <span style={{color: "#2c7be5", cursor: "pointer"}}> <a href="https://app.bitly.com/settings/api" target="_blank"> learn more</a></span></span>
        <br />
        <Card.Body className="">{TabComponent()}</Card.Body>
      </Card>
    </>
  );
};

export default Integrations;
