import geminiLogo from "assets/img/logos/gemini.png";
import claudeIcon from "assets/svgs/claudeIcon.svg";
import openAIIcon from "assets/svgs/openAIIcon.svg";
import ConfirmModal from "components/common/ConfirmModal.js";
import Flex from "components/common/Flex";
import APIService from "http/api_service";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-slidedown/lib/slidedown.css";
import { toast } from "react-toastify";
import { setPublishablePostTextBody } from "redux/slices/post_text_body_slice";
import { getGoogleDriveManager } from "redux/slices/postslice";
import { getLoggedInUser } from "redux/slices/user_slice";
import {
  getActiveWorkSpace,
  setActiveWorkspace,
} from "redux/slices/workspaceslice";
import { isFreeWorkspace } from "utils/workspace_utils.js";
import { MetricsProps, RoutePaths } from "../../../../constants";
import ApiKeyManagement from './ApiKeyManagement';
import ChatArea from './ChatArea';
import PromptDrawer from './PromptDrawer';
import './chatMessage.css';
import './chatWindow.css';
import { setPopupUpgradeAlert } from "redux/slices/authSlice";
import { setCollectMetric } from "redux/slices/analytics_slice";

export const maxUsableCharacters = (workSpaceOwner = {}) => {
  let maxChars =
    workSpaceOwner?.active_plan?.AI_Writer_Words ??
    workSpaceOwner?.active_plan?.AI_Writer_Chars ??
    0;
  if (workSpaceOwner.active_ai_writer_subscription) {
    return "Unlimited";
  }
  return maxChars;
};

export const usedAIWriterChars = (workSpaceOwner = {}) => {
  let max = maxUsableCharacters(workSpaceOwner);
  let used = workSpaceOwner?.used_ai_writer_characters ?? 0;
  if (used > 0) {
    return used;
  }
  if (workSpaceOwner.ai_writer_privileges) {
    used = ~~(workSpaceOwner.ai_writer_privileges?.used_ai_writer_tokens ?? 0);
  }
  if (max === "Unlimited") {
    return used;
  }
  if (used > max) {
    used = max;
  }
  return used;
};

export default function AIChat(props) {
  const { tools } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector(getLoggedInUser);
  const workSpace = useSelector(getActiveWorkSpace);
  const [inputValue, setInputValue] = useState("");
  const [hasText, setHasText] = useState(false);
  const [hasStartChat, setHasStartChat] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("GPT3.5 Turbo");
  const [showSidenav, setShowSidenav] = useState(false);
  const [savedConversations, setSavedConversations] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [responseCount, setResponseCount] = useState(0);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isbuttonPressed, setIsbuttonPressed] = useState(false);
  const [rows, setRows] = useState(50); // Initial number of rows
  const [apiKey, setApiKey] = useState({
    geminiKey: null,
    claudeAIKey: null,
    openAIKey: null,
  });
  const [isApiKeyModel, setApiKeyModal] = useState(false);
  const [currentChars, setCurrentChars] = useState(0);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isDoneStreaming, setIsDoneStreaming] = useState(false);
  const userHasFreeWorkspace = isFreeWorkspace(workSpace);
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [copyText, setCopyText] = useState("Copy");
  const [isLoading, setIsLoading] = useState(true);
  const [exportModal, setExportModal] = useState(false);
  const [pdfMessage, setPDFMessage] = useState("");
  const googleDriveManager = useSelector(getGoogleDriveManager);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const googleDriveRef = useRef(null);
  const [checkingAICredit, setCheckingAICredit] = useState(true);
  const maxRows = 200;
  const [openPromptDrawer, setOpenPromptDrawer] = useState(false);
  const [promptUseTrigger, setPromptUseTrigger] = useState({
    use_trigger: false,
    prompt: null,
  });
  const [workSpaceOwner, setWorkspaceOwner] = useState();
  const [fineTunning, setFineTunning] = useState({
    outputFormat: "default",
    tone: "default",
    writingStyle: "default",
    language: "default",
  });
  const [popFineTuner, setPopFineTuner] = useState(false);

  const fetchWorkspaceOwner = () => {
    APIService.fetchWorkSpaceOwner(workSpace._id, (response, error) => {
      if (response) {
        let { data } = response;
        setWorkspaceOwner(data);
      }
    });
  };

  useEffect(() => {
    fetchWorkspaceOwner();
  }, []);

  const userOnFreePlan = () => {
    let activePlan = workSpaceOwner?.active_plan;
    if (
      activePlan?.Name?.toLowerCase() === "free" ||
      activePlan?.Key?.toLowerCase() === "free"
    ) {
      return true;
    }
    return false;
  };

  const handleUsePrompt = (prompt) => {
    setPromptUseTrigger({
      use_trigger: true,
      prompt,
    });
    setOpenPromptDrawer(false);
  };

  useEffect(() => {
    if (messages.length > 0 && isDoneStreaming && hasStartChat) {
      saveConvoFunc();
    }
  }, [isDoneStreaming, messages]);

  useEffect(() => {
    // if (userHasFreeWorkspace) {
    //  dispatch(setPopupUpgradeAlert(true))
    //   return
    // }

    checkAIWriterUsageResumption();
    if (!isLoading) scrollToBottom(chatContainerRef.current);
  }, []);

  useEffect(() => {
    if (isbuttonPressed == true) {
      handleSendMessage();
      setIsbuttonPressed(false);
    }
  }, [isbuttonPressed, inputValue]);

  const getLanguageFineTuneCommand = (language, tone, style) => {
    let statement = "Please respond in";
    let languageAdded = false;
    let toneAdded = false;
    let validated = false;
    if (language && language?.toLowerCase() !== "default") {
      statement = statement + ` ${language}`;
      languageAdded = true;
      validated = true;
    }
    if (tone && tone.toLowerCase() !== "default") {
      statement = statement + `${languageAdded ? ", " : " "}${tone} tone`;
      toneAdded = true;
      validated = true;
    }
    if (style && style.toLowerCase() !== "default") {
      statement = statement + `${toneAdded ? ", " : " "}${style} style`;
      validated = true;
    }
    if (validated) {
      statement = statement + ".";
      return statement;
    }
    return "";
  };

  const getOutputFormatFineTuneCommand = (currentCommand, outputFormat) => {
    if (!outputFormat || outputFormat?.toLowerCase() === "default") {
      return currentCommand;
    }
    let loweredCaseOutputFormat = outputFormat.toLowerCase();

    if (loweredCaseOutputFormat === "concise") {
      return `${currentCommand} Respond as concise as possible`;
    }
    if (loweredCaseOutputFormat === "step-by-step") {
      return `${currentCommand} Think step-by-step`;
    }
    if (loweredCaseOutputFormat === "extreme details") {
      return `${currentCommand} Provide extreme details as much as possible`;
    }
    if (loweredCaseOutputFormat === "essay") {
      return `${currentCommand} Answer in Essay format`;
    }
    if (loweredCaseOutputFormat === "report") {
      return `${currentCommand} Answer in Report format`;
    }
    if (loweredCaseOutputFormat === "faq") {
      return `${currentCommand} Answer in FAQ format`;
    }
    if (loweredCaseOutputFormat === "interview") {
      return `${currentCommand} Answer in Interview format`;
    }
    if (loweredCaseOutputFormat === "interview") {
      return `${currentCommand} Answer in Interview format`;
    }
    if (loweredCaseOutputFormat === "review") {
      return `${currentCommand} Answer in Review format`;
    }
    return currentCommand;
  };

  const prepareFineTunningCommand = () => {
    let selectedFineTuningCommands = { ...fineTunning };
    let command = "";
    command =
      command +
      getLanguageFineTuneCommand(
        selectedFineTuningCommands?.language,
        selectedFineTuningCommands?.tone,
        selectedFineTuningCommands?.writingStyle
      );
    command = getOutputFormatFineTuneCommand(
      command,
      selectedFineTuningCommands?.outputFormat
    );
    return command;
  };

  // useEffect(() => {
  //   prepareFineTunningCommand();
  // }, [fineTunning])

  const checkAIWriterUsageResumption = () => {
    setCheckingAICredit(true);
    APIService.checkAIWriterUsageResumption(workSpace["_id"], (res, err) => {
      if (err) {
        console.log("🚀 ~ APIService.checkAIWriterUsageResumption ~ err:", err);
        toast.error(err, { theme: "colored" });
        setCheckingAICredit(false);
        return;
      }
      let apikeys = {};
      apikeys.geminiKey = res?.data?.ai_writer_model_settings?.geminiKey;
      apikeys.claudeAIKey = res?.data?.ai_writer_model_settings?.claudeAIKey;
      apikeys.openAIKey = res?.data?.ai_writer_model_settings?.openAIKey;
      setApiKey(apikeys);
      console.log("run fetching...");
      APIService.fetchWorkSpace(workSpace["_id"], (response, error) => {
        if (error) {
          console.log("🚀 ~ APIService.fetchWorkSpace ~ error:", error);
          return;
        }
        if (response) {
          let responseData = response["data"];
          let receivedWorkspace = responseData["workspace"];
          receivedWorkspace["role"] = responseData["role"];
          receivedWorkspace["value"] = receivedWorkspace["name"];
          receivedWorkspace["title"] = responseData["title"];
          receivedWorkspace["label"] = receivedWorkspace["name"];
          dispatch(setActiveWorkspace({ ...receivedWorkspace }));
        }
        setIsLoading(false);
        setCheckingAICredit(false);
      });
      APIService.getWorkspaceOwnerAICredits(
        workSpace["_id"],
        (response, error) => {
          if (error) {
            return;
          }
          if (response) {
            setCurrentChars(response?.data?.used_ai_writer_characters);
          }
          setIsLoading(false);
        }
      );
      APIService.fetchSavedAIConversations(
        workSpace["_id"],
        (response, error) => {
          if (error) {
            toast.error(error, { theme: "colored" });
            return;
          }
          setSavedConversations(response?.data);
        }
      );
    });
  };

  const toggleMenu = () => {
    setShowDropdown(!showDropdown);
  };
  const chatContainerRef = useRef(null);

  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputValue(text);
    setHasText(text.trim() !== "");
  };
  const actionModal = (message, action) => {
    // console.log(action,message)

    switch (action) {
      case "post":
        if (userOnFreePlan() === true) {
          let metricInitializer = {
            action: MetricsProps.AI_CHAT_TOOL,
            detailedAction: `Upgrade notice: Attempted to post from AI Chat`,
            timestamp: new Date().toISOString(),
            route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
          }
          dispatch(setCollectMetric(metricInitializer))
          dispatch(setPopupUpgradeAlert(true))
          return;
        }
        dispatch(setPublishablePostTextBody(message));
        navigate(RoutePaths.POSTS);
        break;
      case "copy":
        setCopyText("Copied");
        const textarea = document.createElement("textarea");
        textarea.value = message;
        document?.body?.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        toast.success("Copied", { theme: "colored" });
        break;
      case "export":
        if (userOnFreePlan() === true) {
          let metricInitializer = {
            action: MetricsProps.AI_CHAT_TOOL,
            detailedAction: `Upgrade notice: Attempted to export from AI Chat`,
            timestamp: new Date().toISOString(),
            route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
          }
          dispatch(setCollectMetric(metricInitializer))
          dispatch(setPopupUpgradeAlert(true))
          return;
        }
        setExportModal(true)
        setPDFMessage(message)
        shareText(message)
        break
      default:
        break;
    }
  };
  const toggleSidenav = () => {
    setShowSidenav(!showSidenav);
  };

  const handleNewChat = () => {
    setHasStartChat(false);
    setIsStreaming(false);
    setInputValue("");
    setCurrentConversationId(null);
    setMessages([]);
    setRows(50);
    setHasText(false);
    const messageContainer = document.getElementsByClassName(
      "chat-message-container"
    );
    const messageArray = [...messageContainer];
    messageArray.forEach((element) => {
      element.remove();
    });
  };
  const handleLoadConversation = (conversationId) => {
    handleNewChat();
    // setIsStreaming(true)
    setIsDoneStreaming(false);
    setRows(50);
    setMessages([]);
    setCurrentConversationId(conversationId);

    for (const conversation of savedConversations) {
      if (conversation._id == conversationId) {
        setSelectedOption(conversation?.model);
        for (const message of conversation.messages) {
          // console.log("messageId=",message?._id)
          const messageOptions = document.createElement("div");
          messageOptions.classList.add("message-options");
          messageOptions.id = `messageOptions-${message?._id}`;
          setHasStartChat(true);
          setCurrentConversationId(conversation._id);

          appendMessage(
            message?.user,
            message?.message,
            message?.className,
            conversation?.model,
            message?._id
          );

          if (message.className === "bot-message") {
            const postButton = createButton(
              "post",
              message._id,
              message?.message
            );
            const copyButton = createButton(
              "copy",
              message._id,
              message?.message
            );
            const exportButton = createButton(
              "export",
              message._id,
              message?.message
            );

            messageOptions?.appendChild(postButton);
            messageOptions?.appendChild(copyButton);
            messageOptions?.appendChild(exportButton);

            const messageContainer = document.getElementById(
              `chat-message-${message._id}`
            );
            if (messageContainer) {
              messageContainer?.appendChild(messageOptions);
            } else {
              console.error(
                `Element with ID 'chat-message-${message._id}' not found.`
              );
            }
          }
        }
      }
    }
  };

  const createButton = (action, messageId, message) => {
    const button = document.createElement("div");
    // button.href = 'javascript:;';
    button.id = `${action}-${messageId}`;
    button.classList.add("btn-custom");
    button.title = action.charAt(0).toUpperCase() + action.slice(1);
    button.dataset.messageId = messageId;
    button.innerHTML = `${getButtonIcon(action)}<span class="tooltip">${action.charAt(0).toUpperCase() + action.slice(1)
      }</span>`;
    button.addEventListener("mouseover", () => {
      button.classList.add("hovered");
    });
    button.addEventListener("mouseout", () => {
      button.classList.remove("hovered");
    });
    button.addEventListener("click", () => {
      button.classList.toggle("clicked");
      actionModal(message, action);
    });
    return button;
  };
  const getButtonIcon = (action) => {
    switch (action) {
      case "post":
        return `<svg aria-hidden="true" title="Post" focusable="false" data-prefix="fas" data-icon="pen-to-square" class="svg-inline--fa fa-pen-to-square option-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"></path></svg>`;
      case "copy":
        return `<svg aria-hidden="true" title="Copy" focusable="false" data-prefix="fas" data-icon="copy" class="svg-inline--fa fa-copy option-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"></path></svg>`;
      case "export":
        return `<svg aria-hidden="true" title="Export" focusable="false" data-prefix="fas" data-icon="arrow-down" class="svg-inline--fa fa-arrow-down option-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg>`;
      default:
        return "";
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    handleNewChat();
    setIsDropdownOpen(false);
  };

  const saveConvoFunc = async () => {
    const topic = messages[0]?.message?.substring(0, 40);
    await APIService.saveAIConversation(
      messages,
      topic,
      currentConversationId,
      selectedOption,
      workSpace["_id"],
      (response, err) => {
        if (response) {
          setCurrentConversationId(response?.data?._id);
          checkAIWriterUsageResumption();
        }
        if (err) {
          toast.error(err, { theme: "colored" });
        }
      }
    );
  };

  const handleSendMessage = async () => {
    setHasStartChat(true);
    setIsStreaming(true);
    setIsDoneStreaming(false);
    setInputValue("");
    setRows(50);
    const extraCommand = prepareFineTunningCommand();
    const trimmedMessage = `${inputValue.trim()}\n\n${extraCommand}`;
    if (trimmedMessage) {
      setInputValue("");
      appendMessage("You", trimmedMessage, "user-message");

      try {
        if (selectedOption == "GEMINI") {
          if (apiKey?.geminiKey) {
            APIService.generateTextV2(
              workSpace["_id"],
              trimmedMessage,
              true,
              "gemini",
              currentConversationId,
              (res, err) => {
                if (res?.data) {
                  const botResponse = res.data;
                  if (botResponse) {
                    streamBotResponse(botResponse);
                    setResponseCount(responseCount + 1);
                  }
                }
                if (err) {
                  toast.error(err, { theme: "colored" });
                }
              }
            );
          } else {
            toast.error("GEMINI Api Key not added", { theme: "colored" });
          }
        } else if (selectedOption == "CLAUDE.AI") {
          if (apiKey?.claudeAIKey) {
            APIService.generateTextV2(
              workSpace["_id"],
              trimmedMessage,
              true,
              "claudeai",
              currentConversationId,
              (res, err) => {
                if (res?.data) {
                  const botResponse = res.data;
                  if (botResponse) {
                    streamBotResponse(botResponse);
                    setResponseCount(responseCount + 1);
                  }
                }
                if (err) {
                  toast.error(err, { theme: "colored" });
                }
              }
            );
          } else {
            toast.error("CLAUDE.AI Api Key not added", { theme: "colored" });
          }
        } else if (selectedOption == "GPT 4 Turbo") {
          if (apiKey?.openAIKey) {
            APIService.generateTextV2(
              workSpace["_id"],
              trimmedMessage,
              true,
              "openai4.0",
              currentConversationId,
              (res, err) => {
                if (res?.data) {
                  const botResponse = res.data;
                  if (botResponse) {
                    streamBotResponse(botResponse);
                    setResponseCount(responseCount + 1);
                  }
                }
                if (err) {
                  toast.error(err, { theme: "colored" });
                }
              }
            );
          } else {
            APIService.generateTextV2(
              workSpace["_id"],
              trimmedMessage,
              false,
              "openai4.0",
              currentConversationId,
              (res, err) => {
                if (res?.data) {
                  const botResponse = res.data;
                  if (botResponse) {
                    streamBotResponse(botResponse);
                    setResponseCount(responseCount + 1);
                  }
                }
                if (err) {
                  toast.error(err, { theme: "colored" });
                }
              }
            );
          }
        } else if (selectedOption == "GPT-4o") {
          if (apiKey?.openAIKey) {
            APIService.generateTextV2(
              workSpace["_id"],
              trimmedMessage,
              true,
              "openai4o",
              currentConversationId,
              (res, err) => {
                if (res?.data) {
                  const botResponse = res.data;
                  if (botResponse) {
                    streamBotResponse(botResponse);
                    setResponseCount(responseCount + 1);
                  }
                }
                if (err) {
                  toast.error(err, { theme: "colored" });
                }
              }
            );
          } else {
            APIService.generateTextV2(
              workSpace["_id"],
              trimmedMessage,
              false,
              "openai4o",
              currentConversationId,
              (res, err) => {
                if (res?.data) {
                  const botResponse = res.data;
                  if (botResponse) {
                    streamBotResponse(botResponse);
                    setResponseCount(responseCount + 1);
                  }
                }
                if (err) {
                  toast.error(err, { theme: "colored" });
                }
              }
            );
          }
        } else {
          if (apiKey?.openAIKey) {
            APIService.generateTextV2(
              workSpace["_id"],
              trimmedMessage,
              true,
              "openai3.5",
              currentConversationId,
              (res, err) => {
                if (res?.data) {
                  const botResponse = res.data;
                  if (botResponse) {
                    streamBotResponse(botResponse);
                    setResponseCount(responseCount + 1);
                  }
                }
                if (err) {
                  toast.error(err, { theme: "colored" });
                }
              }
            );
          } else {
            APIService.generateTextV2(
              workSpace["_id"],
              trimmedMessage,
              false,
              "openai3.5",
              currentConversationId,
              (res, err) => {
                if (res?.data) {
                  const botResponse = res.data;
                  if (botResponse) {
                    streamBotResponse(botResponse);
                    setResponseCount(responseCount + 1);
                  }
                }
                if (err) {
                  toast.error(err, { theme: "colored" });
                }
              }
            );
          }
        }
      } catch (error) {
        console.error("Error sending message:", error);
        toast.error(
          error?.response?.data ||
          error?.response?.message ||
          error?.response ||
          error?.message,
          { theme: "colored" }
        );
      }

      setInputValue("");
    } else {
      setIsStreaming(false);
      setIsDoneStreaming(true);
    }
  };

  const appendMessage = (
    sender,
    message,
    className,
    model = "",
    conversationId = null
  ) => {
    // if(model !== "") setSelectedOption(model)
    const messageContainer = createMessageContainer(
      sender,
      message,
      className,
      model,
      conversationId
    );
    chatContainerRef?.current?.appendChild(messageContainer);
    const newMessage = { user: sender, message, className: className };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    scrollToBottom();
  };

  const createMessageContainer = (
    sender,
    message,
    className,
    model = "",
    conversationId = null
  ) => {
    const messageContainer = document.createElement("div");
    messageContainer.classList.add("chat-message-container", className);

    const avatarAndTitle = document.createElement("div");
    avatarAndTitle.classList.add("avatar-and-title");

    const avatar = document.createElement("div");
    avatar.classList.add("avatar", "avatar-s");
    if (model !== "") {
      if (model == "GEMINI") {
        avatar.innerHTML = `<div style={{width:"30px",height:"30px", display: "inline-block", marginLeft: "-0.5rem"}}><img src=${geminiLogo} width="30" height="30" class="object-cover w-full"/></div>`;
      } else if (model == "CLAUDE.AI") {
        avatar.innerHTML = `<div style="width:25px;height:25px;display:inline-block;"><img src="${claudeIcon}" alt="copy" height="25px" width="25px" /></div>`;
      } else {
        avatar.innerHTML = `<div style="width:25px;height:25px;display:inline-block;"><img src="${openAIIcon}" alt="copy" height="25px" width="25px" /></div>`;
      }
    } else {
      if (selectedOption == "GEMINI") {
        avatar.innerHTML = `<div style={{width:"30px",height:"30px", display: "inline-block", marginLeft: "-0.5rem"}}><img src=${geminiLogo} width="30" height="30" class="object-cover w-full"/></div>`;
      } else if (selectedOption == "CLAUDE.AI") {
        avatar.innerHTML = `<div style="width:25px;height:25px;display:inline-block;"><img src="${claudeIcon}" alt="copy" height="25px" width="25px" /></div>`;
      } else {
        avatar.innerHTML = `<div style="width:25px;height:25px;display:inline-block;"><img src="${openAIIcon}" alt="copy" height="25px" width="25px" /></div>`;
      }
    }

    const title = document.createElement("div");
    title.classList.add("title");
    title.textContent = sender;

    const messageContent = document.createElement("div");
    messageContent.classList.add("chat-message");

    if (conversationId) {
      messageContainer.id = `chat-message-${conversationId}`;
      messageContent.innerHTML = `<p class="message-text" id="${className === "bot-message"
          ? `bot-message-${conversationId}`
          : `user-message-${conversationId}`
        }">${message}</p>`;
    } else {
      messageContainer.id = `chat-message-${responseCount}`;
      messageContent.innerHTML = `<p class="message-text" id="${className === "bot-message"
          ? `bot-message-${responseCount}`
          : `user-message-${responseCount}`
        }">${message}</p>`;
    }
    avatarAndTitle?.appendChild(avatar);
    avatarAndTitle?.appendChild(title);
    messageContainer?.appendChild(avatarAndTitle);
    messageContainer?.appendChild(messageContent);

    return messageContainer;
  };
  function formatTextForChat(text) {
    const boldFormattedText = text.replace(
      /\*\*(.*?)\*\*/g,
      "<strong>$1</strong>"
    );
    const italicFormattedText = boldFormattedText.replace(
      /\*(.*?)\*/g,
      "<em>$1</em>"
    );
    const headerFormattedText = italicFormattedText.replace(
      /### (.*?)(\n|$)/g,
      "<strong>$1</strong><br>"
    );
    const listFormattedText = headerFormattedText.replace(
      /- (.*?)(\n|$)/g,
      "&bull; $1<br>"
    );

    const formattedText = listFormattedText.replace(
      /```([^`]+)```/g,
      (_, match) => {
        const codeBlock = match.trim().replace(/\n/g, "<br>");
        return `<pre>${codeBlock}</pre>`;
      }
    );

    return formattedText;
  }

  const shareText = (textToShare) => {
    // const textToShare = `Check out this awesome business: ${selectedBusiness?.name}`;
    if (userOnFreePlan() === true) {
      let metricInitializer = {
        action: MetricsProps.AI_CHAT_TOOL,
        detailedAction: `Upgrade notice: Attempted to share from AI Chat`,
        timestamp: new Date().toISOString(),
        route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
      }
      dispatch(setCollectMetric(metricInitializer))
      dispatch(setPopupUpgradeAlert(true))
      return;
    }

    const shareData = {
      title: "Text",
      text: textToShare,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      // Fallback option: Open a sharing dialog with various social media options
      const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        textToShare
      )}`;
      window.open(shareUrl, "_blank");
    }
  };

  const streamBotResponse = (botResponse) => {
    console.log("🚀 ~ streamBotResponse ~ botResponse:", botResponse);
    const messageContainer = createMessageContainer(
      selectedOption,
      "",
      "bot-message"
    );
    chatContainerRef?.current?.appendChild(messageContainer);
    const newMessage = {
      user: selectedOption,
      message: botResponse,
      className: "bot-message",
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    const messageTextElement = messageContainer.querySelector(".message-text");
    const chars = botResponse.split("");
    let currentMessage = "";
    setIsDoneStreaming(true);
    const intervalId = setInterval(() => {
      if (chars.length > 0) {
        currentMessage += chars.shift();
        messageTextElement.innerHTML = formatTextForChat(currentMessage);
        scrollToBottom();
      } else {
        clearInterval(intervalId);
        setIsStreaming(false);
        const messageOptions = document.createElement("div");
        messageOptions.classList.add("message-options");

        const postButton = document.createElement("div");
        // postButton.href = "javascript:;";
        postButton.id = `post-${responseCount}`;
        postButton.title = `Post`;
        postButton.classList.add("btn-custom");
        postButton.innerHTML = `<svg aria-hidden="true" title="Post" focusable="false" data-prefix="fas" data-icon="pen-to-square" class="svg-inline--fa fa-pen-to-square option-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"></path></svg><span class="tooltip">Post</span>`;
        postButton.onclick = () => actionModal(currentMessage, "post");
        postButton.addEventListener("mouseover", () => {
          postButton.classList.add("hovered");
        });
        postButton.addEventListener("mouseout", () => {
          postButton.classList.remove("hovered");
        });
        postButton.addEventListener("click", () => {
          postButton.classList.toggle("clicked");
        });
        const copyButton = document.createElement("div");
        // copyButton.href = "javascript:;";
        copyButton.id = `copy-${responseCount}`;
        copyButton.title = `Copy`;
        copyButton.classList.add("btn-custom");
        copyButton.onclick = () => actionModal(currentMessage, "copy");
        copyButton.innerHTML = `<svg aria-hidden="true" title="Copy" focusable="false" data-prefix="fas" data-icon="copy" class="svg-inline--fa fa-copy option-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"></path></svg><span class="tooltip">Copy</span>`;
        copyButton.addEventListener("mouseover", () => {
          copyButton.classList.add("hovered");
        });
        copyButton.addEventListener("mouseout", () => {
          copyButton.classList.remove("hovered");
        });
        copyButton.addEventListener("click", () => {
          copyButton.classList.toggle("clicked");
        });

        const exportButton = document.createElement("div");
        // exportButton.href = "javascript:;";
        exportButton.id = `export-${responseCount}`;
        exportButton.title = `Export`;
        exportButton.classList.add("btn-custom");
        exportButton.onclick = () => shareText(botResponse);
        exportButton.innerHTML = `<svg aria-hidden="true" title="Export" focusable="false" data-prefix="fas" data-icon="arrow-down" class="svg-inline--fa fa-arrow-down option-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg><span class="tooltip">Export</span>`;
        exportButton.addEventListener("mouseover", () => {
          exportButton.classList.add("hovered");
        });
        exportButton.addEventListener("mouseout", () => {
          exportButton.classList.remove("hovered");
        });
        exportButton.addEventListener("click", () => {
          exportButton.classList.toggle("clicked");
        });
        messageOptions?.appendChild(postButton);
        messageOptions?.appendChild(copyButton);
        messageOptions?.appendChild(exportButton);

        messageContainer?.appendChild(messageOptions);
        scrollToBottom();
        setIsStreaming(false);
      }
    }, 10);
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef?.current?.scrollHeight;
    }
  };

  const adjustTextareaRows = (value) => {
    const lineBreaks = (value.match(/\n/g) || []).length; // Count line breaks
    const newRows = Math.min(maxRows, Math.max(50, lineBreaks * 10 + 50)); // Limit to maxRows
    setRows(newRows);
  };

  const openMessage = () => {
    setApiKeyModal(true);
  };
  const handleKeyPress = (e) => {
    // console.log("event is=",e)
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setInputValue((prevValue) => prevValue + "\n");
      adjustTextareaRows(inputValue + "\n");
    } else if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    } else if (e?.key?.toLowerCase() == "backspace") {
      setRows(50);
    }
  };
  const handleClick = (text) => {
    setIsbuttonPressed(true);
    setInputValue(text);
  };

  if (isLoading) {
    return (
      <Flex alignItems={"center"} justifyContent={"center"} className={"vh-50"}>
        <Spinner animation="border" size={40} />
      </Flex>
    );
  }

  // if (userHasFreeWorkspace) {
  //   return <ConfirmModal
  //     open={openUpgradeDialog}
  //     title={"Upgrade Account"}
  //     message={
  //       "This feature requires an account upgrade. Kindly upgrade to a premium account to continue"
  //     }
  //     confirmText={"UPGRADE"}
  //     cancelText={"CANCEL"}
  //     onCancel={() => {
  //       setOpenUpgradeDialog(false);
  //     }}
  //     onConfirm={() => {
  //       setOpenUpgradeDialog(false);
  //       navigate(`${RoutePaths.BILLING_STRIPE}`);
  //     }}
  //   />
  // }

  return (
    <>
      <ChatArea
        hasStartChat={hasStartChat}
        chatContainerRef={chatContainerRef}
        handleLoadConversation={handleLoadConversation}
        savedConversations={savedConversations}
        usedChars={currentChars?.toLocaleString()}
        userHasUnlimitedAI={currentChars === "Unlimited"}
        maxChars={maxUsableCharacters(workSpace).toLocaleString()}
        openMessage={openMessage}
        inputValue={inputValue}
        handleInputChange={handleInputChange}
        handleSendMessage={handleSendMessage}
        isStreaming={isStreaming}
        handleKeyPress={handleKeyPress}
        handleClick={handleClick}
        handleNewChat={handleNewChat}
        setOpenPromptDrawer={setOpenPromptDrawer}
        setPromptUseTrigger={setPromptUseTrigger}
        promptUseTrigger={promptUseTrigger}
        setInputValue={setInputValue}
        handleOptionClick={handleOptionClick}
        selectedOption={selectedOption}
        apiKey={apiKey}
        setFineTunning={setFineTunning}
        fineTunning={fineTunning}
        prepareFineTunningCommand={prepareFineTunningCommand}
        checkingAICredit={checkingAICredit}
        popFineTuner={popFineTuner}
        setPopFineTuner={setPopFineTuner}
        userOnFreePlan={userOnFreePlan}
        setOpenUpgradeDialog={setOpenUpgradeDialog}
      />
      {
        <PromptDrawer
          handleUsePrompt={handleUsePrompt}
          open={openPromptDrawer ?? false}
          closeDrawer={() => {
            setOpenPromptDrawer(false);
          }}
          userOnFreePlan={userOnFreePlan}
          setOpenUpgradeDialog={setOpenUpgradeDialog}
        />
      }
      <ApiKeyManagement
        isApiKeyModel={isApiKeyModel}
        setApiKeyModal={setApiKeyModal}
        apiKey={apiKey}
        checkAIWriterUsageResumption={checkAIWriterUsageResumption}
        workSpace={workSpace}
      />
      <ConfirmModal
        open={openUpgradeDialog}
        title={"Action requires Upgrade"}
        message={"This feature requires that you are on a premium account"}
        confirmText={"UPGRADE"}
        cancelText={"CANCEL"}
        onCancel={() => {
          setOpenUpgradeDialog(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialog(false);
          navigate(`${RoutePaths.BILLING_STRIPE}`);
        }}
      />
    </>
  );
}
