/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { PlatformIdentifier } from "../../../../../constants";
import {
    getGoogleDriveManager,
    getMediaAddedViaSpecificPlaformUrlMethod,
    getSpecificMediaPlatformTied,
    getThreadsCurrentThreadIndexToAddMedia, setGoogleDriveManager
} from "../../../../../redux/slices/postslice";
// import ThreadsThreadPreviewAndEdit from "./threadsThreadPreviewAndEdit.js";
import THREADSThreadPreviewAndEdit from "./THREADSThreadPreviewAndEdit.js";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setSelectedPlatformForPreview } from "redux/slices/platformSlice";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import { getPlatformVideoUploadLimit } from "redux/slices/workspaceslice";
import FigureUtils from "utils/figures";
import { scanImages } from "./scanImages";

const Threads = ({
    imgSrc,
    viewOnly = false,
}) => {
    const dispatch = useDispatch();
    const mediaAddedViaSpecificPlaformUrlMethod = useSelector(
        getMediaAddedViaSpecificPlaformUrlMethod
    );
    const specificMediaPlatformTied = useSelector(getSpecificMediaPlatformTied);
    const mediaObjectsTotalLength = 10;
    const googleDriveManager = useSelector(getGoogleDriveManager)
    const mediaItemsArrangement = useSelector(getMediaArrangement);
    const [chunkedTweet, setChunkedTweet] = useState([])
    const [processAltSave, setProcessAltSave] = useState(false)
    const threadsCurrentThreadIndexToAddMedia = useSelector(
        getThreadsCurrentThreadIndexToAddMedia
    );
    const [platform] = useState(PlatformIdentifier.THREADS)
    const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
    let typingTimeout

    useEffect(() => {
        localStorage.setItem("satisfied_with_threads_thread", "yes");
    }, []);

    useEffect(async () => {
        // console.log(mediaItemsArrangement, 'mediaItemsArrangement')
        setChunkedTweet(mediaItemsArrangement?.threads)
    }, [mediaItemsArrangement?.threads])

    const mediaRemoveHandler = (removableMediaObject, threadIndex) => {
        let mo = [...chunkedTweet] ?? [];
        let newMedia = mo.filter((item) =>
            item?.media?.includes(removableMediaObject)
        );
        let NestedMedias = newMedia[0].media;
        let indexOfMediaObject = newMedia[0].media.indexOf(removableMediaObject);
        if (indexOfMediaObject !== -1) {
            NestedMedias = NestedMedias?.filter((item, index) => {
                if (index !== indexOfMediaObject) {
                    return item;
                }
            });
        }
        NestedMedias = NestedMedias ?? [];
        let latestMediaObjects = [...NestedMedias];
        const threadsMediaClone = { ...mediaItemsArrangement };
        const newClone = threadsMediaClone?.threads?.map((item, index) => {
            if (index === threadIndex) {
                let newObj = {
                    media: latestMediaObjects, text: item.text, mediaEdited: true, textEdited: item?.textEdited,
                }
                return newObj;
            }
            return item;
        })

        dispatch(setMediaArrangement({ ...mediaItemsArrangement, threads: newClone }))
        setChunkedTweet(newClone);
    }

    const updateMediaObjects = async (
        files,
        canCreateObjectURL = true,
        text,
        threadIndex,
        moreProps = {}
    ) => {
        localStorage.removeItem('threadsIndex')
        const caclength = files.length + mediaItemsArrangement?.threads[threadIndex]?.media?.length;
        if (
            caclength > mediaObjectsTotalLength
        ) {
            toast.error(
                `Sorry, you can only upload a maximum of ${mediaObjectsTotalLength} files at a time.`, { theme: 'colored' }
            );
            return;
        }
        let mo = [];
        for (let file of files) {
            if (canCreateObjectURL) {
                let fileUrl = URL.createObjectURL(file, { autoRevoke: false });
                let fileName = file.name;
                let existingFile = mo.find((x) => x.url === fileUrl);
                if (!existingFile) {
                    let fileExtension = `.${fileName.split(".").pop()}`;
                    let newThreadObject = {
                        name: fileName,
                        url: fileUrl,
                        extension: fileExtension,
                        file: file,
                        ...moreProps,
                    };
                    if (file?.size / 1000000 > 8 && moreProps?.is_video === false) {
                        toast.error(
                            `Image(s) above 8MB have been removed from threads. Kindly upload images below 8MB.`,
                            { theme: "colored" }
                        );
                        return;
                    }
                    if (moreProps?.is_video) {
                        const videoElement = document.createElement('video');
                        videoElement.src = URL.createObjectURL(file);
                        const videoReport = await new Promise((resolve) => {
                            videoElement.addEventListener('loadedmetadata', () => {
                                const duration = videoElement.duration;
                                if (duration < 1 || duration > 300) {
                                    resolve({ valid: false, error: 'Video has been removed from Threads because duration must be between 3 seconds and 5 minutes.' });
                                }
                                resolve({ valid: true });
                            });
                        });
                        if (!videoReport?.valid) {
                            toast.error(videoReport?.error, { theme: "colored" });
                            return;
                        }
                    }
                    if (platformVideoUploadLimit[platform] && file.size > platformVideoUploadLimit[platform]) {
                        let errorMsg = `${file?.name} have been removed from ${platform} because it exceeds the allowed sized limit of ${FigureUtils.convertBytes(platformVideoUploadLimit[platform])?.value}.`
                        if (platformVideoUploadLimit['canUpgrade']) {
                            errorMsg = errorMsg + ` Upgrade your plan to get more upload limits.`;
                        }
                        toast.error(errorMsg, { theme: "colored" });
                    } else {
                        mo.push(newThreadObject);
                    }
                }
            } else {
                let fileWithName = mo.find((item) => item.name === file.name);
                if (!fileWithName) {
                    mo.push(file);
                }
            }
        }

        console.log(mo, 'mo')

        const threadsMediaClone = { ...mediaItemsArrangement };
        const newClone = threadsMediaClone?.threads?.map((item, index) => {
            if (index === threadIndex) {
                let newObj = {
                    media: [...item?.media ?? [], ...mo], text: item.text, mediaEdited: true, textEdited: item?.textEdited
                }
                return newObj;
            }
            return item;
        })
        const imagesMedia = mo?.filter(x => x.is_video === false);
        await scanImages(imagesMedia)
        dispatch(setMediaArrangement({ ...mediaItemsArrangement, threads: newClone }))
        setChunkedTweet(newClone);
    };

    const mediaToReplace = async (comparebleUrl, newUrl, threadIndex, updatedMedias = undefined) => {
        const threadsMediaClone = { ...mediaItemsArrangement };
        let mediaToScan = [];
        const newClone = threadsMediaClone?.threads?.map((item, index) => {
            if (index === threadIndex) {
                let mediaReplaced = [];
                if (updatedMedias) {
                    mediaReplaced = updatedMedias
                } else {
                    mediaReplaced = item?.media.map((x, i) => {
                        if (x.url === comparebleUrl) {
                            return { ...x, url: newUrl, optimized: true }
                        }
                        return x
                    })
                }
                let newObj = {
                    media: mediaReplaced, text: item.text, mediaEdited: true, textEdited: item?.textEdited
                }
                mediaToScan = newObj
                return newObj;
            }
            return item;
        })
        const imagesMedia = mediaToScan?.media?.filter(x => x.is_video === false);
        await scanImages(imagesMedia)
        dispatch(setMediaArrangement({ ...mediaItemsArrangement, threads: newClone }))
        dispatch(setSelectedPlatformForPreview('refresh'));
        setTimeout(() => {
            dispatch(setSelectedPlatformForPreview(PlatformIdentifier.THREADS));
        }, 1000);
        setChunkedTweet(newClone);
    }

    const saveAltValue = (threadIndex, url, value) => {
        if (!url || !value) return;
        const threadsMediaClone = { ...mediaItemsArrangement };
        const newClone = threadsMediaClone?.threads?.map((item, index) => {
            if (index === threadIndex) {
                const updatedMedias = item?.media?.map((x, i) => {
                    if (x.url === url) {
                        return { ...x, alt_text: value }
                    }
                    return x;
                })
                let newObj = {
                    media: updatedMedias, text: item?.text, textEdited: true, mediaEdited: item?.mediaEdited,
                }
                return newObj;
            }
            return item;
        })
        dispatch(setMediaArrangement({ ...mediaItemsArrangement, threads: newClone }))
        setChunkedTweet(newClone);
        setProcessAltSave(false)
    }

    const handleUpdateTextFromEditBox = (text, threadIndex) => {
        clearTimeout(typingTimeout);
        const threadsMediaClone = { ...mediaItemsArrangement };
        const newClone = threadsMediaClone?.threads?.map((item, index) => {
            if (index === threadIndex) {
                let newObj = {
                    media: item?.media, text: text, textEdited: true, mediaEdited: item?.mediaEdited
                }
                return newObj;
            }
            return item;
        })

        dispatch(setMediaArrangement({ ...mediaItemsArrangement, threads: newClone }))
        setChunkedTweet(newClone);
    }

    const addMediaFromUrl = (threadIndex, mediaIncoming) => {
        const threadsMediaClone = { ...mediaItemsArrangement };
        const newClone = threadsMediaClone?.threads?.map((item, index) => {
            if (index === threadIndex) {
                let newObj = {
                    media: [...item?.media, ...mediaIncoming], text: item?.text, textEdited: true, mediaEdited: item?.mediaEdited
                }
                return newObj;
            }
            return item;
        })
        if (googleDriveManager?.docs) {
            dispatch(setGoogleDriveManager({ ...googleDriveManager, docs: null }))
        }
        dispatch(setMediaArrangement({ ...mediaItemsArrangement, threads: newClone }))
        setChunkedTweet(newClone);
    };

    const immediateActionWhenClieked = (remove) => {
        if (remove) {
            localStorage.removeItem("gdrivePlatform");
            return;
        }
        localStorage.setItem("gdrivePlatform", PlatformIdentifier.THREADS);
    }

    useEffect(() => {
        if (!googleDriveManager?.docs || localStorage.getItem('gdrivePlatform') !== PlatformIdentifier.THREADS) return;
        let threadIndex = localStorage.getItem('threadsIndex') ?? 0;
        addMediaFromUrl(threadIndex, [googleDriveManager?.docs]);
        immediateActionWhenClieked(true)
    }, [googleDriveManager])

    useEffect(() => {
        specificMediaPlatformTied === "threads" &&
            mediaAddedViaSpecificPlaformUrlMethod.length &&
            addMediaFromUrl(
                threadsCurrentThreadIndexToAddMedia,
                mediaAddedViaSpecificPlaformUrlMethod
            );
    }, [mediaAddedViaSpecificPlaformUrlMethod]);

    const deleteThread = (threadIndex) => {
        const threadsMediaClone = { ...mediaItemsArrangement };
        const newClone = threadsMediaClone?.threads?.filter((item, index) => index !== threadIndex);
        dispatch(setMediaArrangement({ ...mediaItemsArrangement, threads: newClone }))
        setChunkedTweet(newClone);
    }

    const addThreadsThread = (threadIndex, value) => {
        const threadsMediaClone = { ...mediaItemsArrangement };
        const newClone = threadsMediaClone?.threads?.map((item, index) => {
            if (index === threadIndex && value) {
                let newObj = {
                    media: item?.media, text: value, textEdited: true, mediaEdited: item?.mediaEdited
                }
                return newObj;
            }
            return item;
        })
        let newObj = {
            media: [],
            text: 'Start by editing this new thread here',
            textEdited: true,
        }
        newClone.splice(threadIndex + 1, 0, newObj);
        dispatch(setMediaArrangement({ ...mediaItemsArrangement, threads: newClone }))
        dispatch(setSelectedPlatformForPreview('refresh'));
        setTimeout(() => {
            dispatch(setSelectedPlatformForPreview(PlatformIdentifier.THREADS));
        }, 1000);
        setChunkedTweet(newClone);
    }

    // console.log(chunkedTweet, 'chunkedTweet')

    const mappedTweet = chunkedTweet?.map((item, index) => {
        try {
            return (
                <THREADSThreadPreviewAndEdit
                    key={index}
                    item={item}
                    index={index}
                    imgSrc={imgSrc}
                    handleUpdateTextFromEditBox={handleUpdateTextFromEditBox}
                    chunkedTweet={chunkedTweet}
                    setChunkedTweet={setChunkedTweet}
                    saveAltValue={saveAltValue}
                    addThreadsThread={addThreadsThread}
                    setProcessAltSave={setProcessAltSave}
                    processAltSave={processAltSave}
                    mediaToReplace={mediaToReplace}
                    updateMediaObjects={updateMediaObjects}
                    mediaRemoveHandler={mediaRemoveHandler}
                    deleteThread={deleteThread}
                    viewOnly={viewOnly}
                />
            );
        } catch (error) { }
    });

    return (
        <>
            <div>{mappedTweet}</div>
        </>
    );
};

export default Threads;
