import React, { useEffect, useRef, useState } from "react";
import Flex from "../../../common/Flex";
import { FaBookOpen } from "react-icons/fa";
import { Button, Form, Spinner } from "react-bootstrap";
import { IoIosSend } from "react-icons/io";
import { toast } from "react-toastify";
import { MdTune } from "react-icons/md";

const InputBoxArea = (props) => {
  const { promptUseTrigger, setPromptUseTrigger } = props;
  const { use_trigger, prompt } = promptUseTrigger;
  const [activated, setActivated] = useState(false);
  const {
    inputValue,
    setInputValue,
    handleInputChange,
    handleSendMessage,
    isStreaming,
    handleKeyPress,
  } = props;

  const inputCursor = useRef()



  const handleKeyDown = (event) => {
    // Check if the Enter key is pressed along with the Shift key
    if ((event.key === "Enter" || event.key === "Return") && event.shiftKey) {
        event.preventDefault(); // Prevent the default behavior (like form submission)
        
        // Insert a newline character at the cursor position
        const textarea = event.target; // Assuming this is triggered from a textarea or input
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;

        // Set the value with a newline character
        textarea.value = textarea.value.substring(0, start) + "\n" + textarea.value.substring(end);

        // Move the cursor to the new position
        textarea.selectionStart = textarea.selectionEnd = start + 1; // Move cursor to after the newline
    } else if (event.key === "Enter" || event.key === "Return") {
          setActivated(true);
          handleSendMessage(); // Call a function to handle the message sending
          event.preventDefault(); // Prevent the default form submission behavior
        }
};

  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter" || event.key === "Return") {
  //     setActivated(true);
  //     handleSendMessage(); // Call a function to handle the message sending
  //     event.preventDefault(); // Prevent the default form submission behavior
  //   }
  // };

  useEffect(() => {
    if (!use_trigger) return;
    setPromptUseTrigger({
      use_trigger: false,
      prompt: null,
    });

    
    setInputValue(
      prompt?.title + "\n" + prompt?.description + "\n" + prompt?.source
    );
  }, [use_trigger]);

  const focusInputHandler = () => {
    inputCursor?.current.focus()
  }

  useEffect(() => {
    focusInputHandler()
    return () => { }
  }, [])

  return (
    <Flex
      style={{
        height: "10vh",
        width: "90%",
        maxWidth: "90%",
      }}
      alignItems={"center"}
      gap={3}
    >
      <Flex
        style={{
          width: 90,
        }}
        alignItems={"center"}
        justifyContent={"center"}
        gap={3}
      >
        <FaBookOpen
          size={25}
          cursor={"pointer"}
          color={props?.openPromptDrawer ? "#2569c3" : ""}
          onClick={() => {
            props?.setOpenPromptDrawer(true);
          }}
        />
        <MdTune
          size={25}
          cursor={"pointer"}
          color={props?.popFineTuner ? "#2569c3" : ""}
          onClick={() => {
            props?.setPopFineTuner(!props?.popFineTuner);
          }}
        />
      </Flex>
      <Form.Control
        as="textarea"
        value={inputValue}
        minLength={1}
        placeholder="Type your message...."
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        ref={inputCursor}
      />
      <Flex
        style={{
          width: 70,
        }}
        alignItems={"center"}
      >
        {isStreaming && activated ? (
          <Spinner animation="border" size={30} />
        ) : (
          <IoIosSend
            size={30}
            cursor={"pointer"}
            color="#2569c3"
            onClick={() => {
              setActivated(true);
              handleSendMessage();
            }}
            onKeyDown={handleKeyPress}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default InputBoxArea;
