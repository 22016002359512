import FileUploader from "components/app/file-uploader";
import RenderFilePreviewView from "components/app/media-library/PostlyCloud/RenderFilePreviewView";
import ConfirmModal from "components/common/ConfirmModal";
import { RoutePaths } from "constants";
import AppContext from "context/Context";
import APIService from "http/api_service";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, ProgressBar, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getContentUploadingProgress } from "redux/slices/postslice";
import { getActiveWorkspaceFromLocalStorage } from '../../../../../utils/workspace_utils';
import { setPopupUpgradeAlert } from "redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { setCollectMetric } from "redux/slices/analytics_slice";
import { MetricsProps } from "constants";

const LocalUpload = (props) => {
  const {
    parent_folder,
    postlyMediaCloudFetch,
    googleDriveUploadFiles,
    initializingGoogleDriveUpload,
    setGoogleDriveUploadFiles,
    setInitializingGoogleDriveUpload,
    filesUploaded,
    setFilesUploaded,
    totalStorageLimit,
    convertToBytes,
    usedBytes,
    convertBytes
  } = props
  const { config } = useContext(AppContext);
  const { isDark } = config
  const workSpace = getActiveWorkspaceFromLocalStorage();
  const workSpaceId = workSpace._id
  const [fileToPreview, setFileToPreview] = useState([]);
  const [popUpPreviewModal, setPopUpPreviewModal] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const contentUploadProgress = useSelector(getContentUploadingProgress);
  const [currentFileUploading, setCurrentFileUploading] = useState(null);
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [dialogueMessage, setDialogueMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const postlyMediaCloudUpload = async (fileData, originalFileFormat) => {
    if (!workSpaceId) {
      return
    }
    let fileUploadedBk = [...filesUploaded]
    APIService.uploadMediaCloud(workSpaceId, parent_folder, fileData, (response, error) => {
      if (error) {
        if (error?.toLowerCase()?.includes("upgrade")) {
          setDialogueMessage(error);
          let metricInitializer = {
            action: MetricsProps.POSTLY_CLOUD,
            detailedAction: `Upgrade notice: User tries to upload a file to Postly Cloud`,
            timestamp: new Date().toISOString(),
            route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
          }
          dispatch(setCollectMetric(metricInitializer))
          dispatch(setPopupUpgradeAlert(true))
          return;
        }
        toast.error(error, { theme: 'colored' });
        return;
      }
      fileUploadedBk.push(originalFileFormat)
      setFilesUploaded(fileUploadedBk)
    });
  };

  const uploadArtToFirebase = async (filesToPreview) => {
    let totalStorageLimitInBytes = convertToBytes(totalStorageLimit, 'kb');
    let totalSizeOfFilesToUpload = 0;
    for (const _fileToPreview of filesToPreview) {
      let file = _fileToPreview?.file
      if (!file) {
        toast.error('Error! We could not get the file object', { theme: 'colored' });
        return;
      }
      totalSizeOfFilesToUpload += file.size
    }
    let potentialUsedBytes = usedBytes + totalSizeOfFilesToUpload
    if (potentialUsedBytes > totalStorageLimitInBytes) {
      let statement = `Sorry, but Exceeding your Postly Cloud Storage limit of ${convertBytes(totalStorageLimitInBytes)?.value} requires you to upgrade your plan.`
      setDialogueMessage(statement);
      let metricInitializer = {
        action: MetricsProps.POSTLY_CLOUD,
        detailedAction: `Upgrade notice: User tries to upload a file to Postly Cloud and exceeds storage limit`,
        timestamp: new Date().toISOString(),
        route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
      }
      dispatch(setCollectMetric(metricInitializer))
      dispatch(setPopupUpgradeAlert(true))
      return;
    }

    for (const _fileToPreview of filesToPreview) {
      let file = _fileToPreview?.file
      if (!file) {
        toast.error('Error! We could not get the file object', { theme: 'colored' });
        return;
      }
      setCurrentFileUploading(file)
      let uploadRequestBody = new FormData();
      let fileSize = file.size;



      uploadRequestBody.append("file", file);
      try {
        setUploadingFile(true)
        let response = await APIService.uploadNewFile(workSpaceId, uploadRequestBody, fileSize, "all targets", 1);
        const fileObjData = {
          name: file.name,
          type: file.type,
          size: file.size,
          url: response.data
        }
        postlyMediaCloudUpload(fileObjData, file)
      } catch (error) {
        setUploadingFile(false)
        console.log("🚀 ~ file: LocalUpload.jsx:87 ~ uploadArtToFirebase ~ error:", error)
      }
    }
    setTimeout(() => {
      postlyMediaCloudFetch()
      setUploadingFile(false)
      cancelFileUpload()
    }, 1000);
  }

  const handleFileLoadedFromLocalSystem = (e, fromDragDrop) => {
    let files = fromDragDrop ? e : e.target.files;
    let newFiles = [];
    for (let file of files) {
      let blobUrl = URL.createObjectURL(file);
      let newFile = {
        file: file,
        url: blobUrl
      }
      newFiles.push(newFile);
    }
    setFileToPreview(newFiles);
    setTimeout(() => {
      setPopUpPreviewModal(true);
    }, 1000);

  }

  const handleInitializedGoogleDriveUpload = () => {
    if (!initializingGoogleDriveUpload) {
      return
    }
    handleFileLoadedFromLocalSystem(googleDriveUploadFiles, true)
    setInitializingGoogleDriveUpload(false)
    setGoogleDriveUploadFiles([])
  }

  useEffect(() => {
    handleInitializedGoogleDriveUpload()
  }, [initializingGoogleDriveUpload])


  const renderFilePreviewView = () => {
    return fileToPreview?.map((file, index) => <RenderFilePreviewView
      file={file}
      fileType={file?.file?.type}
      fileUrl={file?.url}
      fileToPreview={fileToPreview}
      setFileToPreview={setFileToPreview}
      uploadingFile={uploadingFile}
      key={index}
      filesUploaded={filesUploaded}
      setCurrentFileUploading={setCurrentFileUploading}
      currentFileUploading={currentFileUploading}
    />)
  }

  const cancelFileUpload = () => {
    setFileToPreview(null)
    setPopUpPreviewModal(false)
  }

  const handleStopDownload = () => {
    setUploadingFile(false)
    cancelFileUpload()
  }

  const filePercentageLoader = <div style={{
    zIndex: 10,
    top: '50%',
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'column',
    padding: "0.5em 1em",
  }}>
    <div style={{ fontSize: '13px', marginBottom: '10px', position: 'relative', width: '100%', }}>
      <span style={{ marginRight: '10px' }}>{`${'Uploading File'}`}</span>
      {(contentUploadProgress?.completed === 0 || isNaN(contentUploadProgress?.completed) || contentUploadProgress?.completed == null) ?
        <Spinner animation="border" size="sm" variant="success" /> :
        <span>{`${contentUploadProgress?.completed}%`}</span>}
      {/* <div onClick={handleStopDownload} style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}>
        Cancel
      </div> */}
    </div>
    <ProgressBar
      style={{
        width: '100%'
      }}
      striped
      variant="success"
      now={`${contentUploadProgress?.completed}`}
    />
  </div>

  return (
    <>
      <FileUploader
        hint={"Choose or drag an image or video file."}
        accept={"image/*, video/*"}
        responseType={"file"}
        onFileLoaded={(data) => {
          handleFileLoadedFromLocalSystem(data, true)
        }}
      />

      <Modal
        size="md"
        centered
        show={popUpPreviewModal}
      >
        <Modal.Header>
          {
            uploadingFile ? filePercentageLoader : <Modal.Title
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            > <Button
              variant="secondary"
              onClick={() => cancelFileUpload()}
              disabled={uploadingFile}
            >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  uploadArtToFirebase(fileToPreview)
                }}
                disabled={uploadingFile}
              >
                Upload
              </Button></Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              backgroundColor: "inherit",
              borderRadius: "0px",
              cursor: "pointer",
              marginTop: "10px",
              gap: 5,
              // maxHeight: 350,
              overflowY: 'auto'
            }}
          >
            {
              renderFilePreviewView()
            }

          </div>
        </Modal.Body>
      </Modal>
      <ConfirmModal
        open={openUpgradeDialog}
        title={"Action requires Upgrade"}
        message={
          dialogueMessage
        }
        confirmText={"UPGRADE"}
        cancelText={"CANCEL"}
        onCancel={() => {
          setOpenUpgradeDialog(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialog(false);
          navigate(`${RoutePaths.BILLING_STRIPE}`);
        }}
      />
    </>
  );
};

export default LocalUpload;
