import React, { useEffect, useState } from 'react'
import ReturningUsers from './returning_users'
import { Col, Row } from 'react-bootstrap'
import APIService from 'http/api_service';
import { useSelector } from 'react-redux';
import { getActivePages, getReturningUsers } from 'redux/slices/admin_slice';
import NewUsers from './new_users';
import Users from './users/users';
import StatisticsCard from './stats-cards/StatisticsCard';
import TotalUsers from './total_users';
import TotalWorkspaces from './total_workspaces';
import RealTimeUsers from './real-time-users/RealTimeUsers';
import TotalBotRequests from './total_bot_requests';

const Metrics = () => {
    const activePages = useSelector(getActivePages);
    const requestMetrics = async () => {
        let allUsersRequestBody = {
            skip: 0,
            sort: -1,
            backgroundProcessing: true
        }
        APIService.adminGetAllUsers(allUsersRequestBody, (response, error) => {
            if (error) {
                console.log("🚀 ~ APIService.adminGetAllUsers ~ error:", error)
                return;
            }
            let { message, data } = response;
        });
        APIService.getMetricsForAdmins(async (response, error) => {
            if (error) {
                return;
            }
        });
    }

    useEffect(() => {
        requestMetrics();
    }, [])

    const formatPageVisits = (pageVisits) => {
        const pageCount = {};
        pageVisits.forEach(page => {
            if (pageCount[page]) {
                pageCount[page]++;
            } else {
                pageCount[page] = 1;
            }
        });
        const realTimeUsers = Object.keys(pageCount).map(page => {
            return {
                page: page,
                count: pageCount[page]
            };
        });
        realTimeUsers.sort((a, b) => b.count - a.count);
        return realTimeUsers;
    }

    return (
        <div>
            <Row className="g-3 mb-3">
                <Col sm={12} md={4}>
                    <TotalUsers />
                </Col>
                <Col sm={12} md={4}>
                    <TotalWorkspaces />
                </Col>
                <Col sm={12} md={4}>
                    <TotalBotRequests />
                </Col>
            </Row>
            <Row className="g-3 mb-3">
                {/* <Col md={6} xxl={3}>
                    <TotalUsers />
                </Col>
                <Col md={6} xxl={3}>
                    <TotalWorkspaces />
                </Col> */}
                <Col md={6} xxl={3}>
                    <ReturningUsers />
                </Col>
                <Col md={6} xxl={3}>
                    <NewUsers />
                </Col>
            </Row>
            <Row className="g-3 mb-3">
                {/* <Col xxl={8}>
                    <Audience chartData={audienceChart} className="mb-3" />
                    <ConnectCard />
                </Col> */}
                <Col md={6} xxl={4}>
                    <RealTimeUsers data={formatPageVisits(activePages)} />
                </Col>
                {/* <Col md={6} xxl={4}>
                    <SessionByBrowser data={sessionByBrowser} />
                </Col>
                <Col md={6} xxl={4}>
                    <UsersByCountry chartData={sessionByCountry} mapData={countryData} />
                </Col>
                <Col md={6} xxl={4}>
                    <Intelligence data={intelligence} />
                </Col> */}
            </Row>

            {/* <ReturningUsers /> */}
        </div>
    )
}

export default Metrics