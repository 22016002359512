import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findPlatformByIdentifier } from "components/app/platforms";
import Divider from "components/common/Divider";
import Flex from "components/common/Flex";
import PageHeader from "components/common/PageHeader";
import CopyLinkButton from "components/common/copylinkbutton";
import { RoutePaths } from "constants";
import { toJpeg, toPng } from "html-to-image";
import APIService from "http/api_service";
import QRCode from "qrcode.react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Modal, Spinner } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { copyToClipboard } from "utils/browser";
import CopyIcon from "../../assets/svgs/CopyIcon.svg";
import {
  getAutoShortenerStatus,
  setAutoShortenerStatus,
  setQrcodeArray,
} from "redux/slices/links_shortener_slice";
import { IoMdSettings } from "react-icons/io";
import { constant } from "lodash";
import CsvDownloader from "react-csv-downloader";
import ConfirmModal from "components/common/ConfirmModal";
import axios from "axios";
import { setPopupUpgradeAlert } from "redux/slices/authSlice";
// import CopyIcon from "../../../assets/svgs/CopyIcon.svg";

const BusinessLocations = () => {
  const dispatch = useDispatch();
  const workSpace = useSelector(getActiveWorkSpace);
  const status = useSelector(getAutoShortenerStatus);
  const [workSpaceOwner, setWorkspaceOwner] = useState();
  const [newLocationDatas, setNewLocationDatas] = useState();
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [savingQrCode, setSavingQrCode] = useState(false);
  const [shortenLink, setShortenLink] = useState("");
  const [format, setFormat] = useState("png");
  const [generatingShortLink, setGeneratingShortLink] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const platform = findPlatformByIdentifier("google_my_business");
  const { locationId } = useParams();
  const qrCodeRef = useRef(null);
  const navigate = useNavigate();

  const fetchWorkspaceOwner = () => {
    APIService.fetchWorkSpaceOwner(workSpace._id, (response, error) => {
      if (response) {
        let { data } = response;
        setWorkspaceOwner(data);
      }
    });
  };

  const userOnFreePlan = () => {
    let activePlan = workSpaceOwner?.active_plan;
    if (
      activePlan?.Name?.toLowerCase() === "free" ||
      activePlan?.Key?.toLowerCase() === "free"
    ) {
      return true;
    }
    return false;
  };

  const searchBusiness = (businessName) => {
    return new Promise(async (resolve, reject) => {
      APIService.fetchGoogleBusiness(businessName, (response, error) => {
        if (error) {
          console.log("Error fetching Google Business data:", error);
          reject(error);
        } else {
          const { data } = response;
          console.log("Fetched Google Business data:", data);
          resolve(data[0]);
        }
      });
      // try {
      //   const res = await axios.get(
      //     `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?fields=formatted_address%2Cname%2Crating%2Copening_hours%2Cgeometry,place_id&input=${businessName?.toLowerCase()}&inputtype=textquery&key=AIzaSyB_i8pe0-g4wCUV-LTTezg4LgoWX5Ax_s4`
      //   );
      //   const businesses = await res?.data?.candidates;
      //   console.log("🚀 ~ returnnewPromise ~ businesses:", businesses);
      //   resolve(businesses[0]);
      // } catch (error) {
      //   console.log("🚀 ~ returnnewPromise ~ error:", error);
      //   reject(error);
      // }
    });
  };


  const fetchBusinessLocations = async () => {
    APIService.fetchPagesAndGroups(
      workSpace,
      platform.identifier,
      locationId,
      async (response, error) => {
        if (error) {
          toast.error(error);
          return;
        }
        let responseData = response["data"];
        console.log("Response Data:", responseData);
        let newLocationInfo = [];
        for (let index = 0; index < responseData.length; index++) {
          console.log("Business Name:", responseData[index]?.name);
          try {
            let element = await searchBusiness(responseData[index]?.name);
            console.log("Element:", element);
            if (!element) {
              return;
            }
            element = { ...element, connected: responseData[index]?.connected };
            newLocationInfo.push(element);
          } catch (error) {
            console.error(
              "Error fetching business data for",
              responseData[index]?.name,
              error
            );
          }
        }
        console.log("New Location Info:", newLocationInfo);
        setNewLocationDatas(newLocationInfo);
      }
    );
  };

  function base64StringtoFile(base64String, filename, mimeType) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const file = new File([blob], filename, { type: mimeType });

    return file;
  }

  const downloadQRCode = (format) => {
    if (userOnFreePlan() === true) {
      dispatch(setPopupUpgradeAlert(true))
      return;
    }
    const qrCodeContainer = document.getElementById("qr-code-container");
    let downloadFunction;

    switch (format) {
      case "png":
        downloadFunction = toPng;
        break;
      case "jpg":
        downloadFunction = toJpeg;
        break;
      default:
        return;
    }

    downloadFunction(qrCodeContainer).then(async function (dataUrl) {
      console.log("🚀 ~ dataUrl:", dataUrl);
      const link = document.createElement("a");
      link.download = `${selectedBusiness?.name || "qr-code"}.${format}`;
      link.href = dataUrl;
      link.click();
    });
  };

  const handleSubmit = () => {
    setGeneratingShortLink(true);
    let formData = {
      full_link: `https://search.google.com/local/reviews?placeid=${selectedBusiness?.place_id}`,
    };
    APIService.shortenLink(formData, (response, error) => {
      setGeneratingShortLink(false);
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data } = response;
      setShortenLink(data);
      toast.success("Link shortened");
    });
  };

  const saveAndUpload = async () => {
    setSavingQrCode(true);
    const qrCodeContainer = document.getElementById("qr-code-container");
    toPng(qrCodeContainer).then(async function (dataUrl) {
      console.log("🚀 ~ dataUrl:", dataUrl);
      const base64Data = dataUrl.replace(/^data:[^;]+;base64,/, "");
      const file = await base64StringtoFile(
        base64Data,
        selectedBusiness?.name?.toLowerCase(),
        "image/png"
      );
      console.log("🚀 ~ file:", file?.name);
      APIService.uploadFileToFirebase(
        { file: file, filename: file.name, fileTag: "qr-code", fileIndex: 0 },
        (response, error) => {
          if (error && error?.statusCode === 200) {
            console.log(
              "🚀 ~ APIService.uploadFileToFirebase ~ error:",
              error?.data
            );
            APIService.saveQrcode(
              {
                title: selectedBusiness?.name,
                shortenedLink: shortenLink,
                fullLink: `https://search.google.com/local/reviews?placeid=${selectedBusiness?.place_id}`,
                qrCodeLink: error?.data,
              },
              (response, error) => {
                if (error) {
                  console.log("🚀 ~ APIService.saveQrcode ~ error:", error);
                  toast.error(error, { theme: "colored" });
                  setSavingQrCode(false);
                }
                const { message, data, status } = response;
                toast.success(message, { theme: "colored" });
                console.log("🚀 ~ APIService.saveQrcode ~ data:", data);
                if (status === false) {
                  toast.error("Error occured", { theme: "colored" });
                  setSavingQrCode(false);
                  return;
                }
                dispatch(setQrcodeArray(data));
                setSavingQrCode(false);
              }
            );
            return;
          } else {
            toast.error("Error occured while saving code", {
              theme: "colored",
            });
            setSavingQrCode(false);
          }
        }
      );
    });
  };

  const shareQrCode = () => {
    if (userOnFreePlan() === true) {
      dispatch(setPopupUpgradeAlert(true))
      return;
    }
    const base64Image = qrCodeRef?.current?.toDataURL(); // Replace this with the base64 encoded image data

    const shareData = {
      title: selectedBusiness?.name,
      text: `Scan to get google review link for ${selectedBusiness?.name} business location`,
      url: base64Image,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      alert("Web Share API not supported. You can manually share the image.");
    }
  };

  const getStatusHandler = async () => {
    APIService.getAutoShortenedLinkStatus((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data } = response;
      //   toast.success(message);
      dispatch(setAutoShortenerStatus(data));
    });
  };

  const handleToggle = async (e) => {
    e.preventDefault();
    if (userOnFreePlan() === true) {
      dispatch(setPopupUpgradeAlert(true))
      return;
    }
    let requestBody = {
      status: e.target.checked,
    };
    await APIService.toggleAutoShortenLinks(requestBody, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { message, data } = response;
      dispatch(setAutoShortenerStatus(data));
      toast.success(message);
      getStatusHandler();
    });
  };

  // const callLocation = async () => {
  //   try {
  //     const response = await axios.get(`https://maps.googleapis.com/maps/api/place/findplacefromtext/json`, {
  //       params: {
  //         // new postly.link
  //         key: "AIzaSyAzxQqwEwl5LYToXeAXOAjBamH3aFsTijk",
  //         input: "beetos",
  //         inputtype: 'textquery',
  //         fields: 'name,geometry,formatted_address,place_id,rating',
  //       }
  //     });
  //     const businesses = await response?.data?.candidates
  //     console.log("🚀 ~ callLocation ~ businesses:", businesses)
  //     console.log("🚀 ~ router.get ~ response?.data:", response?.data)
  //   } catch(e) {
  //     console.log("🚀 ~ callLocation ~ e:", e)
  //     console.log("🚀 ~ callLocation ~ e?.message:", e?.message)
      
  //   }
  // }

  useEffect(() => {
    fetchBusinessLocations();
    fetchWorkspaceOwner();
    getStatusHandler();
    // callLocation()
  }, []);

  const checkHandler = (data) => {
    const getData = selectedItems?.find((d) => d?.place_id === data?.place_id);
    if (getData) {
      return true;
    }
    return false;
  };

  const columns = [
    {
      id: "name",
      displayName: "Name",
    },
    {
      id: "full_link",
      displayName: "Review link",
    },
    {
      id: "shortenLink",
      displayName: "Shortened link",
    },
  ];

  const exportLinksHandler = async () => {
    let newData = [];

    // Create an array of promises for each API call
    const promises = selectedItems.map((item) => {
      return new Promise((resolve, reject) => {
        let element = {
          name: item.name,
          full_link: `https://search.google.com/local/reviews?placeid=${item?.place_id}`,
          shortenLink: "",
        };
        const formData = {
          full_link: element?.full_link,
        };

        if (status) {
          APIService.shortenLink(formData, (response, error) => {
            setGeneratingShortLink(false);
            if (error) {
              toast.error(error, { theme: "colored" });
              reject(error);
            } else {
              let { data } = response;
              element.shortenLink = data;
              newData.push(element);
              resolve();
            }
          });
        } else {
          newData.push(element);
          resolve();
        }
      });
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    return newData;
  };

  const asyncFnComputeDate = async () => {
    if (userOnFreePlan() === true) {
      dispatch(setPopupUpgradeAlert(true))
      return;
    }
    return await exportLinksHandler();
  };
  return (
    <>
      {!workSpaceOwner && (
        <Flex
          alignItems={"center"}
          style={{
            height: "100vh",
          }}
          justifyContent={"center"}
        >
          <Spinner animation="border" />
        </Flex>
      )}

      {workSpaceOwner && (
        <>
          <div>
            {!platform ? (
              <span>Nothing here</span>
            ) : (
              <Flex direction={"column"} justifyContent={"center"}>
                {selectedItems?.length > 0 && (
                  <Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        // backgroundColor: "red",
                      }}
                    >
                      <div>
                        <h3 className="fw-normal fs-2 fs-md-2">Settings</h3>
                        <Flex alignItems={"center"} className={"gap-3"}>
                          <IoMdSettings size={20} />

                          <Form.Label
                            style={{
                              paddingTop: 8,
                            }}
                            htmlFor="auto-shorten-link-settings"
                          >
                            Auto-shorten exported businesses links
                          </Form.Label>
                          <Form.Check
                            //   style={{
                            //     cursor: "pointer",
                            //   }}
                            id="auto-shorten-link-settings"
                            type="switch"
                            onChange={handleToggle}
                            checked={status}
                            // label="Auto Shorten Links in Post"
                          />
                        </Flex>
                      </div>
                      <>
                        {/* <Button>Export links</Button> */}
                        <CsvDownloader
                          filename="businessesLinks"
                          extension=".csv"
                          columns={columns}
                          datas={asyncFnComputeDate}
                          disabled={userOnFreePlan()}
                        >
                          <Button
                            onClick={() => {
                              if (userOnFreePlan() === true) {
                                dispatch(setPopupUpgradeAlert(true))
                                return;
                              }
                            }}
                          >
                            Export links
                          </Button>
                        </CsvDownloader>
                      </>
                    </div>
                  </Card>
                )}
                <Divider />
                <Col>
                  <Flex gap={"3"}>
                    {/* <Form.Label>Select all :</Form.Label> */}
                    <Form.Check
                      style={{
                        cursor: "pointer",
                        marginLeft: "20px",
                      }}
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedItems(newLocationDatas);
                        } else {
                          setSelectedItems([]);
                        }
                      }}
                    />
                  </Flex>
                </Col>
                <Card
                  style={{
                    width: "100%",
                    minHeight: "70vh",
                    padding: "20px",
                  }}
                >
                  {/* <GoogleBusinessAccount/> */}
                  {newLocationDatas?.length > 0 ? (
                    newLocationDatas?.map((data, index) => {
                      // Connect, display, export links, shorten them
                      return (
                        <>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              position: "relative",
                              padding: "20px",
                            }}
                          >
                            <Flex key={data?.place_id} gap={4}>
                              <Card
                                style={{
                                  width: 60,
                                  height: 60,
                                  borderWidth: 2,
                                  backgroundColor: "#EEEEEE",
                                  // borderColor: "#999",
                                  // borderRadius: 200,
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 40,
                                    color: "black",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    alignSelf: "center",
                                  }}
                                >
                                  {data?.name
                                    ?.charAt(0)
                                    .toString()
                                    .toUpperCase()}
                                </span>
                              </Card>
                              <div>
                                <p>{data?.name}</p>
                                <p>{data?.formatted_address}</p>
                              </div>
                            </Flex>
                            <Button
                              onClick={() => {
                                setSelectedBusiness(data);
                                setShowModal(true);
                              }}
                            >
                              View Details
                            </Button>
                            <Form.Check
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                transform: "scale(1.0)",
                              }}
                              // id="auto-shorten-link-settings"
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedItems([...selectedItems, data]);
                                } else {
                                  setSelectedItems(
                                    selectedItems.filter(
                                      (item) => item.place_id !== data.place_id
                                    )
                                  );
                                }
                              }}
                              checked={checkHandler(data)}
                            />
                          </div>
                          {newLocationDatas?.length - 1 !== index && (
                            <Divider />
                          )}
                        </>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "70vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h3>No business location.</h3>
                    </div>
                  )}
                </Card>
              </Flex>
            )}
          </div>
        </>
      )}
      <Modal
        size="md"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
        onExit={() => setShowModal(false)}
      >
        <Modal.Header>
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Modal.Title>Business Location Information</Modal.Title>
            </div>
            <AiOutlineClose
              cursor={"pointer"}
              onClick={() => setShowModal(false)}
            />
          </span>
        </Modal.Header>
        <Modal.Body>
          <h1 className="d-flex justify-content-center">
            {selectedBusiness?.name}
          </h1>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <div
              id="qr-code-container"
              className="d-flex justify-content-center"
            >
              <div
                style={{
                  height: "230px",
                  width: "230px",
                  backgroundColor: "#f4f6fa",
                  padding: "10px",
                  margin: "0 auto",
                  postion: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <QRCode
                  ref={qrCodeRef}
                  size={200}
                  value={`https://search.google.com/local/reviews?placeid=${selectedBusiness?.place_id}`}
                />
              </div>
            </div>
          </Flex>
          <br />
          <Flex justifyContent={"center"} alignItems={"center"}>
            <div>
              <Button onClick={() => downloadQRCode(format)}>
                Download QRCode
              </Button>
            </div>
          </Flex>
          <br />
          <Flex justifyContent={"center"} alignItems={"center"}>
            {/* <p  style={{
                  fontSize: "12px",
                }}>{`https://search.google.com/local/reviews?placeid=${selectedBusiness?.place_id}`}</p> */}
            <CopyLinkButton
              defaultText={
                // <img src={CopyIcon} alt="copy" height="30px" width="30px" />
                <Button>Copy link</Button>
              }
              variant={"outline-info"}
              handleCopy={() => {
                copyToClipboard(
                  `https://search.google.com/local/reviews?placeid=${selectedBusiness?.place_id}`
                );
              }}
              style={{
                fontSize: "12px",
                padding: "5px",
                backgroundColor: "inherit",
                border: "none",
                // position: "absolute",
                // top: "0px",
                // right: "0px",
              }}
            />
          </Flex>
          <Flex
            style={{
              width: "100%",
              position: "relative",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div style={{ width: "100%", position: "relative", flex: "5" }}>
              <Form.Control
                type="text"
                placeholder={`https://search.google.com/local/reviews?placeid=${selectedBusiness?.place_id}`}
                aria-label="Long Link Text box"
                value={
                  shortenLink ||
                  `https://search.google.com/local/reviews?placeid=${selectedBusiness?.place_id}`
                }
                name={"shortenLink"}
                disabled={true}
                style={{ width: "100%" }}
              />
              {/* <FaRegCopy style={{position: "absolute", right: "0px"}} /> */}
              <CopyLinkButton
                defaultText={
                  <img src={CopyIcon} alt="copy" height="30px" width="30px" />
                }
                variant={"outline-info"}
                handleCopy={() => {
                  copyToClipboard(shortenLink);
                }}
                style={{
                  fontSize: "12px",
                  padding: "5px",
                  backgroundColor: "inherit",
                  border: "none",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              />
            </div>
            <Button
              onClick={handleSubmit}
              variant="primary"
              className="d-block w-100"
              style={{ flex: "1", fontSize: "14px" }}
            >
              {/* Shorten link */}
              {generatingShortLink ? "Please wait..." : "Shorten link"}
            </Button>
          </Flex>

          {/* {shortenLink && (
            <Flex justifyContent={"center"} alignItems={"center"}>
              <div>
                <Button
                  onClick={saveAndUpload}
                  style={{ backgroundColor: "#f4f6fa", color: "#2569c3" }}
                  disabled={savingQrCode}
                >
                  {savingQrCode ? "Saving" : "Save QRCode"}
                </Button>
              </div>
            </Flex>
          )} */}
        </Modal.Body>
        <Modal.Footer style={{ flexDirection: "column" }}>
          <Flex justifyContent={"flex-start"} alignItems={"center"} gap={4}>
            <p>Share on socials</p>
            <Button onClick={shareQrCode}>Share</Button>
          </Flex>
        </Modal.Footer>
      </Modal>
      <ConfirmModal
        open={openUpgradeDialog}
        title={"Action requires Upgrade"}
        message={"This feature requires that you are on a premium account"}
        confirmText={"UPGRADE"}
        cancelText={"CANCEL"}
        onCancel={() => {
          setOpenUpgradeDialog(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialog(false);
          navigate(`${RoutePaths.BILLING_STRIPE}`);
        }}
      />
    </>
  );
};

export default BusinessLocations;
