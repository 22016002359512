import Flex from "components/common/Flex";
import APIService from "http/api_service";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import Divider from "components/common/Divider";
import { BsDashSquareDotted } from "react-icons/bs";
import { GrConfigure } from "react-icons/gr";
import { IoMdLock } from "react-icons/io";
import { IoSquareOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import ConfirmModal from "components/common/ConfirmModal";
import { RoutePaths } from "constants";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { toPng, toJpeg } from "html-to-image";
// import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { setQrcodeArray } from "redux/slices/links_shortener_slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { setPopupUpgradeAlert } from "redux/slices/authSlice";

const QRCodeComponent = () => {
  const workSpace = useSelector(getActiveWorkSpace);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //tools
  const colorPreset = [
    "#000000",
    "#d93020",
    "#ec7e00",
    "#198539",
    "#229ce0",
    "#2a5bd7",
    "#6b52d1",
    "#d84280",
  ];
  //states
  const [workSpaceOwner, setWorkspaceOwner] = useState();
  const [formData, setFormData] = useState({
    full_link: "",
  });
  const [qrColor, setQrColor] = useState("#000000");
  const [qrBgColor, setQrBgColor] = useState("white");
  const [OnSave, setOnSave] = useState(false);
  const [borderType, setBorderType] = useState("solid");
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [shortLink, setShortLink] = useState("hey");
  const [format, setFormat] = useState("png");

  //onchange function
  const handleInputChanges = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [generatingShortLink, setGeneratingShortLink] = useState(false);
  const [savingQrCode, setSavingQrCode] = useState(false);

  const fetchWorkspaceOwner = () => {
    APIService.fetchWorkSpaceOwner(workSpace._id, (response, error) => {
      if (response) {
        let { data } = response;
        setWorkspaceOwner(data);
      }
    });
  };

  const userOnFreePlan = () => {
    let activePlan = workSpaceOwner?.active_plan;
    if (
      activePlan?.Name?.toLowerCase() === "free" ||
      activePlan?.Key?.toLowerCase() === "free"
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.title) {
      toast.error("Provide title for the code", { theme: "colored" });
      return;
    }
    if (formData?.custom_domain) {
      if (userOnFreePlan() === true) {
        dispatch(setPopupUpgradeAlert(true))
        return;
      }
      if (!formData.custom_domain.includes(".")) {
        toast.error("Kindly provide subdomain or domain.", {
          theme: "colored",
        });
        return;
      }
    }

    if (formData.back_half && userOnFreePlan() === true) {
      dispatch(setPopupUpgradeAlert(true))
      return;
    }
    setGeneratingShortLink(true);
    APIService.shortenLink(formData, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        console.log("🚀 ~ APIService.shortenLink ~ error:", error);
        setGeneratingShortLink(false);
        return;
      }
      let { data } = response;
      console.log("🚀 ~ APIService.shortenLink ~ data:", data);
      setShortLink(data);
      setGeneratingShortLink(false);
      setShowModal(true);
    });
  };

  useEffect(() => {
    fetchWorkspaceOwner();
  }, []);

  function base64StringtoFile(base64String, filename, mimeType) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const file = new File([blob], filename, { type: mimeType });

    return file;
  }

  const downloadQRCode = (format) => {
    const qrCodeContainer = document.getElementById("qr-code-container");
    let downloadFunction;

    switch (format) {
      case "png":
        downloadFunction = toPng;
        break;
      case "jpg":
        downloadFunction = toJpeg;
        break;
      default:
        return;
    }

    downloadFunction(qrCodeContainer).then(async function (dataUrl) {
      console.log("🚀 ~ dataUrl:", dataUrl);
      const link = document.createElement("a");
      link.download = `${formData.title || "qr-code"}.${format}`;
      link.href = dataUrl;
      link.click();
    });
  };

  const saveAndUpload = async () => {
    setSavingQrCode(true);
    const qrCodeContainer = document.getElementById("qr-code-container");
    toPng(qrCodeContainer).then(async function (dataUrl) {
      console.log("🚀 ~ dataUrl:", dataUrl);
      const base64Data = dataUrl.replace(/^data:[^;]+;base64,/, "");
      const file = await base64StringtoFile(
        base64Data,
        formData.title?.toLowerCase(),
        "image/png"
      );
      console.log("🚀 ~ file:", file?.name);
      APIService.uploadFileToFirebase(
        { file: file, filename: file.name, fileTag: "qr-code", fileIndex: 0 },
        (response, error) => {
          if (error && error?.statusCode === 200) {
            console.log(
              "🚀 ~ APIService.uploadFileToFirebase ~ error:",
              error?.data
            );
            APIService.saveQrcode(
              {
                title: formData.title,
                shortenedLink: shortLink,
                fullLink: formData?.full_link,
                qrCodeLink: error?.data,
              },
              (response, error) => {
                if (error) {
                  console.log("🚀 ~ APIService.saveQrcode ~ error:", error);
                  toast.error(error, { theme: "colored" });
                  setSavingQrCode(false);
                }
                const { message, data, status } = response;
                toast.success(message, { theme: "colored" });
                console.log("🚀 ~ APIService.saveQrcode ~ data:", data);
                if (status === false) {
                  toast.error("Error occured", { theme: "colored" });
                  setSavingQrCode(false);
                  return;
                }
                dispatch(setQrcodeArray(data));
                setSavingQrCode(false);
              }
            );
            return;
          } else {
            toast.error("Error occured while saving code", {
              theme: "colored",
            });
            setSavingQrCode(false);
          }
        }
      );
    });
  };

  const handleShare = async () => {
    const email = 'megatey97@gmail.com';
    const subject = 'Check out this image!';
    const body = 'Here is the image I want to share with you.';
    const qrCodeContainer = document.getElementById("qr-code-container");
    const dataUrl = await toPng(qrCodeContainer);
      const base64Data = dataUrl.replace(/^data:[^;]+;base64,/, "");
      console.log("🚀 ~ handleShare ~ base64Data:", base64Data)

    // Base64 encode your image (replace 'yourImageBase64String' with the actual Base64 string of your image)
    const attachment = `data:image/png;base64,${base64Data}`;

     // Compose URL for Gmail
     const gmailComposeUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=&su=Check%20out%20this%20image!&body=Here%20is%20the%20image%20I%20want%20to%20share%20with%20you.&attcnt=1&att0=${encodeURIComponent(attachment)}`;

     // Open the Gmail compose page with the image attached
     window.open(gmailComposeUrl, '_blank');
  };

  return (
    <div
      style={{
        width: "100%",
        minHeight: "80vh",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "10px",
      }}
    >
      <Card
        style={{
          width: "70%",
          height: "85vh",
          padding: "10px",
          overflow: "auto",
        }}
      >
        <Form onSubmit={handleSubmit} className="g-2">
          <Flex alignItems={"center"} className={"gap-3 p-2"}>
            <FontAwesomeIcon icon="fa-solid fa-wrench" />
            <h3 className="fw-normal fs-2 fs-md-2">Configure code</h3>
          </Flex>
          <Divider />
          <Col sm style={{ marginBottom: "12px" }}>
            <Form.Label>Destination URL</Form.Label>
            <Form.Control
              type="text"
              placeholder="https://example.com/my-long-url"
              aria-label="Long Link Text box"
              value={formData.full_link}
              name={"full_link"}
              onChange={handleInputChanges}
              style={{ width: "100%", margin: "0px" }}
            />
          </Col>
          <Col sm style={{ marginBottom: "12px" }}>
            <Form.Label>Title (optional)</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              aria-label="Long Link Text box"
              value={formData.title}
              name={"title"}
              onChange={handleInputChanges}
              style={{ width: "100%", margin: "0px" }}
            />
          </Col>
          <Col sm style={{ marginBottom: "12px" }}>
          <span
                style={{
                    fontSize: 16,
                    textAlign: 'center'
                }}>{'Short link'}
            </span>
            <Card.Title></Card.Title>
            <Card.Text style={{ fontSize: "14px" }}>
              The short link directs users to the website or content linked to
              your QR Code. If you update the short link after creating the QR
              Code it will change the code.
            </Card.Text>
            <Flex style={{ gap: "10px" }} alignItems={"end"}>
              <div>
                <Form.Label>
                  Domain <IoMdLock size={20} />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="postly.click"
                  aria-label="Long Link Text box"
                  value={formData.custom_domain}
                  name={"custom_domain"}
                  onChange={handleInputChanges}
                  style={{ width: "100%", margin: "0px" }}
                />
              </div>
              <span style={{ fontSize: "18px" }}>/</span>
              <div>
                <Form.Label>Custom back-half (optional)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  aria-label="Long Link Text box"
                  value={formData.back_half}
                  name={"back_half"}
                  onChange={handleInputChanges}
                  style={{ width: "100%", margin: "0px" }}
                />
              </div>
            </Flex>
          </Col>
          <br />
          <br />
          <br />
          <Flex alignItems={"center"} className={"gap-3"}>
            <FontAwesomeIcon icon="palette" />
            <h3 className="fw-normal fs-2 fs-md-2">Customize design</h3>
          </Flex>
          <Divider />
          <Col className="mt-2">
            <div>
              <Card.Title>Choose your colors</Card.Title>
              <Flex style={{ gap: "5px" }}>
                {colorPreset?.map((color, index) => (
                  <div
                    key={index}
                    className="rounded-circle"
                    style={{
                      width: "50px",
                      height: "50px",
                      border: `${
                        color === qrColor ? "2px solid #3866da" : "none"
                      }`,
                      padding: "1px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setQrColor(color)}
                  >
                    <div
                      className="rounded-circle"
                      style={{
                        width: "45px",
                        height: "45px",
                        backgroundColor: `${color}`,
                      }}
                    ></div>
                  </div>
                ))}
              </Flex>
            </div>
            <div>
              <Card.Header style={{ fontWeight: "bold" }}>
                Single color code
              </Card.Header>
              <Flex style={{ height: "40px" }}>
                <input
                  type="color"
                  style={{ height: "100%", width: "50px" }}
                  value={qrColor}
                  onChange={(e) => setQrColor(e.target.value)}
                />
                <Form.Control
                  type="text"
                  placeholder=""
                  aria-label="Long Link Text box"
                  value={qrColor}
                  name={"back_half"}
                  onChange={(e) => setQrColor(e.target.value)}
                  style={{
                    height: "100%",
                    width: "fit-content",
                    margin: "0px",
                  }}
                />
                <IoMdLock size={20} />
              </Flex>
            </div>
            {/* <div>
              <Card.Header style={{ fontWeight: "bold" }}>
                Background color code
              </Card.Header>
              <Flex style={{ height: "40px" }}>
                <input
                  type="color"
                  style={{ height: "100%", width: "50px" }}
                  value={qrBgColor}
                  onChange={(e) => setQrBgColor(e.target.value)}
                />
                <Form.Control
                  type="text"
                  placeholder=""
                  aria-label="Long Link Text box"
                  value={qrBgColor}
                  name={"back_half"}
                  onChange={(e) => setQrBgColor(e.target.value)}
                  style={{
                    height: "100%",
                    width: "fit-content",
                    margin: "0px",
                  }}
                />
                <IoMdLock size={20} />
              </Flex>
            </div> */}
          </Col>
          <Col className="mt-5">
            <Card.Title>Select a frame</Card.Title>
            <Flex alignItems={"center"} className={"gap-3"}>
              <div
                onClick={() => setBorderType("solid")}
                style={{
                  width: "100px",
                  height: "100px",
                  border: `${
                    borderType === "solid"
                      ? "2px solid #2a5bd7"
                      : "1px solid #dbe0eb"
                  }`,
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <IoSquareOutline size={60} />
              </div>
              <div
                onClick={() => setBorderType("dashed")}
                style={{
                  width: "100px",
                  height: "100px",
                  border: `${
                    borderType === "dashed"
                      ? "2px solid #2a5bd7"
                      : "1px solid #dbe0eb"
                  }`,
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <BsDashSquareDotted size={50} />
              </div>
            </Flex>
          </Col>
          {/* <Col className="mt-5">
            <Card.Title>Advanced options</Card.Title>
            <Card.Header style={{ fontWeight: "bold" }}>Branding</Card.Header>
            <Flex alignItems={"center"} className={"gap-3"}>
              <Form.Label
                style={{
                  paddingTop: 8,
                }}
                htmlFor="auto-shorten-link-settings"
              >
                Postly QR Code logo
              </Form.Label>
              <Form.Check
                id="auto-shorten-link-settings"
                type="switch"
                onChange={(e) => setQrLogo(e.target.checked)}
                checked={qrLogo}
              />
            </Flex>
          </Col> */}
          <Button disabled={generatingShortLink} className="mt-4" type="submit">
            {generatingShortLink ? " Creating..." : "Create code"}
          </Button>
        </Form>
      </Card>
      <Card style={{ width: "30%", minHeight: "80vh" }}>
        <div style={{ width: "100%", minHeight: "80vh", padding: "10px" }}>
          <div style={{ padding: "10px" }}>
            <div
              style={{
                width: "fit-content",
                backgroundColor: "#f4f6fa",
                padding: "10px",
                margin: "0 auto",
                postion: "relative",
              }}
            >
              <QRCode
                size={200}
                value={shortLink}
                fgColor={qrColor}
                bgColor={qrBgColor}
                style={{ border: `2px ${borderType} black`, padding: "10px" }}
              />
            </div>
          </div>
        </div>
      </Card>
      <Modal
        size="md"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
        onExit={() => setShowModal(false)}
      >
        <Modal.Header>
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Modal.Title>Your QRcode is ready</Modal.Title>
            </div>
            <AiOutlineClose
              cursor={"pointer"}
              onClick={() => setShowModal(false)}
            />
          </span>
        </Modal.Header>
        <p className="text-center font-weight-bold">
          Scan the image below to preview your QR Code.
        </p>
        <Modal.Body>
          <div id="qr-code-container" className="d-flex justify-content-center">
            <div
              style={{
                height: "230px",
                width: "230px",
                backgroundColor: "#f4f6fa",
                padding: "10px",
                margin: "0 auto",
                postion: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <QRCode
                size={200}
                value={shortLink}
                fgColor={qrColor}
                bgColor={qrBgColor}
                style={{ border: `2px ${borderType} black`, padding: "10px" }}
              />
            </div>
          </div>
          <p style={{ textAlign: "center" }}>
            {" "}
            <a href={shortLink} target="_blank">
              {shortLink}
            </a>{" "}
          </p>
          {/* <div className="d-flex"><h3>Share:</h3>
          <FontAwesomeIcon icon="fa-solid fa-envelope" style={{cursor: "pointer"}} onClick={handleShare}/>
          </div> */}
        </Modal.Body>
        <Modal.Footer style={{ flexDirection: "column" }}>
          <div>
            <Button onClick={() => downloadQRCode(format)}>
              Download QRCode
            </Button>
          </div>
          <div>
            <Button
              onClick={saveAndUpload}
              style={{ backgroundColor: "#f4f6fa", color: "#2569c3" }}
              disabled={savingQrCode}
            >
              {savingQrCode ? "Saving" : "Save QRCode"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <ConfirmModal
        open={openUpgradeDialog}
        title={"Action requires Upgrade"}
        message={"This feature requires that you are on a premium account"}
        confirmText={"UPGRADE"}
        cancelText={"CANCEL"}
        onCancel={() => {
          setOpenUpgradeDialog(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialog(false);
          navigate(`${RoutePaths.ACCOUNT}?billing=true`);
        }}
      />
    </div>
  );
};

export default QRCodeComponent;
