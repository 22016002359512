import APIService from 'http/api_service';
import useOrganizationStore from 'state/organization_store';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from 'redux/slices/user_slice';

const useInitializeOrganizations = () => {

    const loggedInUser = useSelector(getLoggedInUser);
    const setOrganizations = useOrganizationStore(state => state.setOrganizationList);
    const setOrganizationBeingCreated = useOrganizationStore(state => state.setOrganizationBeingCreated);

    const initializeOrganizations = () => {
        const apiKey = loggedInUser?.api_key;
        if (!apiKey) return;

        APIService.fetchOrganizations({}, apiKey, (response, error) => {
            if (error || !response) return;

            const retrievedOrganizations = response.data
                ?.map(org => org.organization)
                ?.filter(org => org && typeof org !== 'string') || [];

            setOrganizationBeingCreated(undefined);
            fetchUserWorkSpaces(retrievedOrganizations);
        });
    };

    const fetchUserWorkSpaces = (initialOrganizations = []) => {
        APIService.fetchUserWorkSpaces((response, error) => {
            if (error || !response) return;

            const userWorkspaces = response.data
                ?.filter(workspaceEntry => workspaceEntry.workspace)
                ?.map(workspaceEntry => ({
                    ...workspaceEntry.workspace,
                    role: workspaceEntry.role,
                    title: workspaceEntry.title,
                    value: workspaceEntry.workspace.name,
                    label: workspaceEntry.workspace.name
                })) || [];

            const uniqueWorkSpaces = removeDuplicateWorkspaces(userWorkspaces);

            const orgsMap = new Map();
            addOrganizationsToMap(initialOrganizations, orgsMap);
            addOrganizationsFromWorkspaces(uniqueWorkSpaces, orgsMap);

            const organizations = Array.from(orgsMap.values()).filter(org => !org.new);
            console.log(organizations);
            setOrganizations(organizations);
        });
    };

    const removeDuplicateWorkspaces = (workspaces) => {
        const uniqueWorkspaces = [];
        workspaces.forEach(workspace => {
            if (!uniqueWorkspaces.some(w => w.name === workspace.name)) {
                uniqueWorkspaces.push(workspace);
            }
        });
        return uniqueWorkspaces;
    };

    const addOrganizationsToMap = (organizations, map) => {
        organizations.forEach(org => map.set(org._id, org));
    };

    const addOrganizationsFromWorkspaces = (workspaces, map) => {
        workspaces
            .filter(workspace => workspace.organization)
            .forEach(workspace => map.set(workspace.organization._id, workspace.organization));
    };

    return { initializeOrganizations };
};

export default useInitializeOrganizations;
