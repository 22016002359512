import React, { useEffect, useState } from 'react'
import FeatureBlockModal from './FeatureBlockModal'
import ReminderModal from './ReminderModal'
import LoaderModal from './LoaderModal'

const GlobalUpgradeModal = () => {
    return (
        <>
            <LoaderModal />
            <FeatureBlockModal />
            <ReminderModal />
        </>
    )
}

export default GlobalUpgradeModal