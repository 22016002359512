import Flex from "components/common/Flex"
import {
    useEffect,
    useState
} from "react"
import {
    Button,
    Form,
    Nav,
    Offcanvas,
    Row,
    Tab
} from "react-bootstrap"
import { IoMdAdd } from "react-icons/io"
import { IoChatbubble } from "react-icons/io5"
import PersonalPrompts from './PersonalPrompts'
import CommunityPrompts from './CommunityPrompts'
import AddPrompt from './AddPrompt'
import { getActiveWorkSpace } from 'redux/slices/workspaceslice'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import APIService from "http/api_service";
import { setPopupUpgradeAlert } from "redux/slices/authSlice"
import { setCollectMetric } from "redux/slices/analytics_slice"
import { MetricsProps } from "constants"

export default function PromptDrawer(props) {
    const { open, closeDrawer, handleUsePrompt, setOpenUpgradeDialog, userOnFreePlan } = props
    const [showDrawer, setShowDrawer] = useState(open)
    const [selectedTab, setSelectedTab] = useState('Your prompts')
    const [communityPrompts, setCommunityPrompts] = useState([]);
    const [personalPrompts, setPersonalPrompts] = useState([]);
    const [yourPromptsCount, setYourPromptsCount] = useState(0);
    const [communityPromptsCount, setCommunityPromptsCount] = useState(0);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [source, setSource] = useState("");
    const [prompt, setPrompt] = useState("");
    const [tags, setTags] = useState([]);
    const [ispublic, setIspublic] = useState(true)
    const [showAddPrompt, setShowAddPrompt] = useState(false);
    const [inputTagValue, setInputTagValue] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [searchInput, setSearchInput] = useState("")
    const [searchCommPrompt, setSearchCommPrompt] = useState("")
    const [isLoadingSearchPrivatePrompt, setIsLoadingSearchPrompt] = useState(false)
    const [isLoadingSearchCommPrompt, setIsLoadingSearchCommPrompt] = useState(false)
    const [triggerFetchPrompt, setTriggerFetchPrompt] = useState(0)
    const workSpace = useSelector(getActiveWorkSpace);
    const workSpaceId = workSpace._id;
    const dispatch = useDispatch();

    const savePrompt = (e) => {
        e.preventDefault()
        if (userOnFreePlan() === true) {
            let metricInitializer = {
                action: MetricsProps.AI_CHAT_TOOL,
                detailedAction: `Upgrade notice: User tried to add a prompt`,
                timestamp: new Date().toISOString(),
                route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
            }
            dispatch(setCollectMetric(metricInitializer))
            dispatch(setPopupUpgradeAlert(true))
            return;
        }
        setIsAdding(true)
        const promptData = {
            title,
            description,
            source,
            prompt,
            tags,
            ispublic
        }
        APIService.savePrompt(workSpaceId, promptData, (res, err) => {
            if (err) {
                toast.error(err, { theme: "colored" })
                return
            }
            setIsAdding(false)
            setTitle("")
            setDescription("")
            setSource("")
            setPrompt("")
            setTags([])
            setIspublic(false)
            setTriggerFetchPrompt(prev => prev + 1)
            setShowAddPrompt(false)
            toast.success("Prompt added!", { theme: "colored" })
        })

    }

    useEffect(() => {
        setShowDrawer(open)
    }, [open])

    const handleClose = () => {
        setShowDrawer(!showDrawer)
        closeDrawer()
    }

    const switcher = (
        <Tab.Container
            id="google_drive_switcher"
            activeKey={selectedTab}
            onSelect={(e) => {
                setSelectedTab(e);
            }}>
            <Row className={'px-0'} style={{ width: 'auto' }}>
                <Nav variant="pills" className="nav-pills-falcon m-0">
                    <Nav.Item>
                        <Nav.Link as={Button} size="sm" eventKey="Your prompts">
                            Your prompts
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Button} size="sm" eventKey="Community prompts">
                            Community prompts
                        </Nav.Link>
                    </Nav.Item>

                </Nav>
            </Row>
        </Tab.Container>
    )

    return (
        <Offcanvas
            style={{
                width: "40%",
            }}
            fullscreen={true}
            placement="end"
            show={showDrawer}
            onHide={handleClose}
        >
            <Offcanvas.Header closeButton closeVariant="white" className="bg-shape settings-panel-header pl-4 pr-14">
                <Offcanvas.Title as="div" className="py-1 z-index-1 light">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <h5 className="text-white">
                            Prompt Library
                        </h5>
                    </div>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <>
                    <Flex>
                        <div
                            className="fs--1"
                        >
                            Prompts are like ready-made message templates you can use in chat. Some prompts have variables you can customize. You can also browse through the Community prompts to find even more options tailored to various needs and topics.
                        </div>
                    </Flex>
                    <Flex
                        justifyContent={'center'}
                        width="100%"
                        className={'mt-3'}
                    >
                        {switcher}
                    </Flex>
                    <Flex
                        justifyContent={'around'}
                        alignItems={'center'}
                        width="100%"
                        className={'mt-3'}
                    >
                        <Form.Control
                            style={{ width: "20vw" }}
                            placeholder={"Search prompts"}
                            className="rounded-2 resize-none border-y-10 px-card border-300 mb-2 mt-2"
                            // value={initialContent}
                            onChange={(e) => {
                                // setInitialContent(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                // if (e.keyCode == 13) {
                                //     mediaType !== "videos" ? getPixelImages() : getPixelVideos();
                                // }
                            }}
                        />

                        <Button
                            size='sm'
                            variant={'primary'}
                            onClick={() => {
                                if (userOnFreePlan() === true) {
                                    let metricInitializer = {
                                        action: MetricsProps.AI_CHAT_TOOL,
                                        detailedAction: `Upgrade notice: User tried to add a prompt`,
                                        timestamp: new Date().toISOString(),
                                        route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
                                    }
                                    dispatch(setCollectMetric(metricInitializer))
                                    dispatch(setPopupUpgradeAlert(true))
                                    return;
                                }
                                setShowAddPrompt(true)
                            }}
                        >
                            <IoMdAdd />  Add prompts
                        </Button>
                    </Flex>
                </>

                {
                    showAddPrompt &&
                    <AddPrompt
                        setTitle={setTitle}
                        title={title}
                        description={description}
                        setDescription={setDescription}
                        source={source}
                        setSource={setSource}
                        prompt={prompt}
                        setPrompt={setPrompt}
                        ispublic={ispublic}
                        setIspublic={setIspublic}
                        isAdding={isAdding}
                        setIsAdding={setIsAdding}
                        showAddPrompt={showAddPrompt}
                        setShowAddPrompt={setShowAddPrompt}
                        setInputTagValue={setInputTagValue}
                        inputTagValue={inputTagValue}
                        savePrompt={savePrompt}
                    />
                }

                {
                    selectedTab === 'Your prompts' ? <PersonalPrompts
                        personalPrompts={personalPrompts}
                        setPersonalPrompts={setPersonalPrompts}
                        handleUsePrompt={handleUsePrompt}
                        triggerFetchPrompt={triggerFetchPrompt}
                        setShowAddPrompt={setShowAddPrompt}
                        userOnFreePlan={userOnFreePlan}
                        setOpenUpgradeDialog={setOpenUpgradeDialog}
                    /> : <CommunityPrompts
                        communityPrompts={communityPrompts}
                        setCommunityPrompts={setCommunityPrompts}
                        handleUsePrompt={handleUsePrompt}
                        triggerFetchPrompt={triggerFetchPrompt}
                        userOnFreePlan={userOnFreePlan}
                        setOpenUpgradeDialog={setOpenUpgradeDialog}
                    />
                }


            </Offcanvas.Body>
        </Offcanvas>
    );

}
